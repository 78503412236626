export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgLogo = require("../assets/logo.png");
export const imgUser = require("../assets/1043817461cFxzonYRDJMRVgjEbjIYjo.png");
export const imgNotification = require("../assets/1043817464cFxzonYRDJMRVgjEbjIYjo.png");
export const createImage = require("../assets/1043817466cFxzonYRDJMRVgjEbjIYjo.png");
export const imgRightArrow = require("../assets/arrow.svg");
export const logOutBtn = require("../assets/logOutBtn.png");
export const group_plus = require("../assets/group_plus.svg");
export const iconSetting = require("../assets/iconSetting.svg");
export const closeBtnIcon = require("../assets/closebtn.png")