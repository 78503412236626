import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import React, { ChangeEvent } from "react";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { throttle } from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  contactFormSchema:any;
  textcounter:number;
  textcounterForSubject: number;
  token:string;
  showModel:boolean;
  submissionStatus:boolean;
  selectedFile:any;
  errorFailureMessage:string;
  userProfileDatafirstName: string;
  userProfileDatalastname :string,
  userProfileDataMobile:string,
  userProfileDataemail:string,
  userProfileDataCompany:string,
  getFAQRes :any;
  searchInputRes :any;
  searchStatus : boolean;
  isLoading:boolean;
  contactFilename:string;
  isSuspended : boolean ;
  suspendedMessage : string;
  suspendedTitle:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HelpCenterController extends BlockComponent<
 Props,
 S,
 SS
 >{
  // Customizable Area Start
  validationApiCallId: any;
  getProfileAccountID: any;
  fileInput:React.RefObject<any>;
  keyGetFAQ : string = "";
  keyGetFAQSearch :string = "";
  userSuspendedApiCallId : string  =  "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.fileInput=React.createRef()
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage), getName(MessageEnum.RestAPIResponceMessage),
    ];

    let contactFormSchema = {
      first_name: Yup.string()
            .matches(/^[A-Za-z ]*$/, 'Please enter First name').max(20).required(configJSON.fnameRequired),
      last_name: Yup.string()
            .matches(/^[A-Za-z ]*$/, 'Please enter Last name').max(20).required(configJSON.lnameRequired),
      subject: Yup.string().required(configJSON.errorSubjectFieldValue),
      details: Yup.string().required(configJSON.errorTellUsValue),
      full_phone_number: Yup.string().required('Mobile number is required'),
    };


    this.state = {
      name: "",
      contactFormSchema: contactFormSchema,
      textcounter:0,
      textcounterForSubject:0,
      token:"",
      showModel:false,
      errorFailureMessage:"",
      selectedFile:null,
      submissionStatus:false,
      userProfileDatafirstName: '',
      userProfileDatalastname :'',
      userProfileDataMobile:'',
      userProfileDataemail:'',
      userProfileDataCompany:'',
      getFAQRes : [],
      searchInputRes :[],
      searchStatus :false,
      isLoading:true,
      contactFilename:"",
      isSuspended : false,
      suspendedMessage : "",
      suspendedTitle: "" ,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.fetchProfileData();
    this.getApiFAQ();
    window.addEventListener("mousemove", this.checkIsUserIsSuspended)
   
  }
  async componentWillUnmount() {
    window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  setTextcounter = (count:any) => {
    this.setState(
      {
        textcounter:count
      }
      )
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
    
      this.setState({
        token: token,
      });
    } else {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      
      if (apiRequestCallId === this.getProfileAccountID) {
        if (responseJson.data) {
          const { attributes } = responseJson.data;
          this.setState({
            userProfileDatafirstName: attributes.first_name,
            userProfileDatalastname: attributes.last_name,
            userProfileDataemail: attributes.email,
            userProfileDataMobile: attributes.full_phone_number,
            userProfileDataCompany: attributes.company.name
          });
        }
      } else if (apiRequestCallId === this.validationApiCallId) {
        if (responseJson.data) {
          this.setState({ submissionStatus: true });
        } 
      }

      if(apiRequestCallId === this.keyGetFAQ){
        let res = responseJson.data;
        this.setState({getFAQRes :res,isLoading:false});
      }
      if(apiRequestCallId ===  this.keyGetFAQSearch){
        let res = responseJson.data;
        this.setState({searchInputRes :res})
      }
    }
    this.receiveUserSuspended(message)
    // Customizable Area End
  }

  // Customizable Area Start

  receiveUserSuspended = (message:Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if(apiRequestCallId === this.userSuspendedApiCallId) {
      this.handleIsUserSuspended(message)  
    }
  }

  checkIsUserIsSuspended = throttle(async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
    this.userSuspendedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suspendedUserEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  },9000)

  handleIsUserSuspended = async (message:Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const checIsTokenExpired = async (): Promise<boolean> => {
      const tokenValidTime = await getStorageData("tokenValidTime")
      const currentTime = new Date().toISOString()
      if (!tokenValidTime) return false
      return new Date(tokenValidTime) < new Date(currentTime)
    }

    if (responseJson.status && responseJson.status === "suspended") {
      this.setState({
        isSuspended: true,
        suspendedMessage: responseJson.message,
        suspendedTitle: configJSON.supsendedModalTitle
      })
    } else if (responseJson.status && responseJson.status === "deactivated") {
      this.setState({
        isSuspended: true,
        suspendedMessage: responseJson.message,
        suspendedTitle: configJSON.deactivatedTitle
      })
    } else if (await checIsTokenExpired()) {
      this.handleGoToLoginPage()
    }
  }

  handleGoToLoginPage = async () => {
    await removeStorageData("authToken")
    await removeStorageData("role")
    await removeStorageData("tokenValidTime")
    await removeStorageData("userName")
    this.setState({ isSuspended: false, suspendedMessage: "", suspendedTitle: "" })
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  resetErrorMessage = () => {
    this.setState(
      { 
        errorFailureMessage: '' 
      }
      );
  };

  openFileInput = () => {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  };

  handleFileChange = (event: any) => {
    const fileInput = event.target;
    const selectedFile = fileInput.files && fileInput.files[0];

    if (selectedFile) {
      this.setState({ selectedFile ,contactFilename:selectedFile.name})
    }
  };

  formDataFormat = (dataVal: any) => {
    let data = Object.fromEntries(
        Object.entries(dataVal).filter(([key, v]) => v !== ''),
      )
    const formData:any = new FormData();
    formData.append(`first_name`,  data.first_name)
    formData.append(`last_name`, data.last_name)
    formData.append(`email`, this.state.userProfileDataemail)
    formData.append(`subject`, data.subject)
    formData.append(`details`, data.details)
    formData.append(`full_mobile_number`, data.full_phone_number)
    formData.append(`company_name`, data.company_name)
    if(this.state.selectedFile){
      formData.append(`file`, this.state.selectedFile)
    }
    return formData;
  }


  submitContactDetails(dataVal:any) {
    console.log("Hi kumar",dataVal)
   let formDataContactUs = this.formDataFormat(dataVal)
    const header = {
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(
        MessageEnum.RestAPIRequestMessage
        )
    );

    this.validationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
        ),
      configJSON.emailBlockEndpint
    );

    requestMessage.addData(
      getName(
        MessageEnum.RestAPIRequestHeaderMessage
        ),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(
        MessageEnum.RestAPIRequestBodyMessage
        ),
        formDataContactUs
    );
    requestMessage.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage
        ),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  fetchProfileData = async () => {
    const header = {"Content-Type": "application/json",token: localStorage.getItem('authToken')};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));this.getProfileAccountID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getViewUserProfileEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  displayModel = () => {
    this.setState(
      {
        showModel:true
      }
      )
  }

  hideModal = () => 
  this.setState(
    {
       showModel: false,
       selectedFile:null,
       submissionStatus:false,
       textcounter : 0,
       }
       );

  goToHomeHelpCenter = () => {this.props.navigation.navigate("LandingPage");}
  goToHelpCenter() {
    this.props.navigation.navigate("HelpCenter");
  }
  goBackHelpCenter() {
    const { navigation } = this.props;
    if (navigation && typeof navigation.goBack === 'function') {
      navigation.goBack();
    } else {
      navigation.navigate('Home');
    }
  }

  goToProfileHelpCenter() {
    this.props.navigation.navigate("CustomisableuserProfiles");
  }

  goToSettingsHelpCenter(){
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }
  goToQuoteHelpCenter = () =>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }
    
    goToEnquiryHelpCenter = () =>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Enquiries");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }

    getApiFAQ = async () => {
      const token = await getStorageData('authToken')
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token,
      };
  
      const requestMessageAPi = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.keyGetFAQ = requestMessageAPi.messageId;
  
      requestMessageAPi.addData(
        getName(
          MessageEnum.RestAPIResponceEndPointMessage
        ),
        configJSON.getFAQEndPoint
      );
  
      requestMessageAPi.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessageAPi.addData(
        getName(
          MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
      return this.keyGetFAQ;
    }

    getApiSearch = async (e : string) => {
      const token = await getStorageData('authToken')
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token,
      };
  
      const requestMessageAPi = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.keyGetFAQSearch = requestMessageAPi.messageId;
  
      requestMessageAPi.addData(
        getName(
          MessageEnum.RestAPIResponceEndPointMessage
        ),
        `${configJSON.getFAQSearchEndPoint}+${e}`
      );
  
      requestMessageAPi.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessageAPi.addData(
        getName(
          MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
      return this.keyGetFAQSearch;
    }


  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ searchStatus:true});
    if(!value){
      return this.setState({ searchStatus:false});
    }
    // Clear previous debounce timer and set new one
    clearTimeout(this.debouncedAPITimer);
    this.debouncedAPITimer = setTimeout(() => {
      this.getApiSearch(value);
    }, 1000); // Adjust the debounce delay as needed
  };

  // Variable to hold debounce timer
  debouncedAPITimer: ReturnType<typeof setTimeout> | undefined;
  
  // Customizable Area End
}
