//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer,Paper,
  Dialog,DialogContent,Box,
 Typography,DialogContentText,IconButton
} from "@material-ui/core";
import AppHeader from "../../../components/src/AppHeader.web";
import {imgLogo,createImage,imgNotification,imgRightArrow,group_plus,imgClose} from "./assets";
import Pagination from '@material-ui/lab/Pagination';
import {
 Image
} from "react-native";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import "./Invoice.css";
import { loadingImg } from "../../customform/src/assets";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import PaginationItem from '@material-ui/lab/PaginationItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPdfViewerDialog() {
    const { numPages} = this.state;
    return (
      <Dialog
        open={this.state.openModel === "Preview"}
        onClose={this.onCancel}
        className="custom-modal"
        transitionDuration={0}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="body"
      >
        <DialogContent
          style={{
            padding: "0px",
            border: "solid 3px #000",
            overflowX: "hidden",
            minHeight: 600,
            maxHeight: 800
          }}
          data-test-id="form"
        >
          <div
            data-testid="hideModal"
            onClick={this.onCancel}
            style={{ position: "absolute", right: 20, top: 10, cursor: "pointer" }}
          >
            <Image
              data-testid="closeBtn"
              source={imgClose}
              resizeMode="cover"
              style={{ height: 18, width: 18 }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Document 
          file={this.state.previewInvoice}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
           {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            )
          )}
        </Document>
        </div>
        </DialogContent>
      </Dialog>
    );
  }

  renderSortIcon = (sortDirection:any) => {
    if (sortDirection === 'asc') {
      return <KeyboardArrowUpIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
    } else if (sortDirection === 'desc') {
      return <KeyboardArrowDownIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
    }
    return null;
  };

  renderCompanySuspendedDialog = () => {
    return  <Dialog
            open={this.state.isSuspended}
            onClose={this.handleGoToLoginPage}
            aria-labelledby="suspended-account-dialoge"
            aria-describedby="suspended-account-dialoge-description"
            data-test-id="suspendedModal">
            <Box style={accountSuspendedModal.container} >
              <IconButton
                disableTouchRipple
                disableRipple
                disableFocusRipple
                data-test-id="suspendedModalCloseBtn"
                onClick={this.handleGoToLoginPage}
                style={accountSuspendedModal.crossMarkCloseBtn}>
                <img src={imgClose}/>
              </IconButton>
              <Box style={accountSuspendedModal.modalContentWrapper}>
                <Box style={accountSuspendedModal.crossMark}>
                  <ClearIcon style={accountSuspendedModal.crossMarkIcon} />
                </Box>
                <Typography style={accountSuspendedModal.suspendedTitle}>
                  {this.state.suspendedTitle}
                </Typography>
                <DialogContent>
                  <DialogContentText
                    style={accountSuspendedModal.suspendedMessage}
                    id="suspended-account-dialoge-description"
                    data-test-id="suspendedMessageID">
                    {this.state.suspendedMessage}
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Box>
          </Dialog>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLoading } = this.state;
    if (isLoading) {
      return  <> <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',width:"100%", backgroundColor: 'transparent' }}>
                  <img src={loadingImg}  style={{height:"50%",width:"25%"}}/>
                 </div>
                 {this.renderCompanySuspendedDialog()}
              </>
              }
    const startIndex = (this.state.currentPage - 1) * this.state.itemsPerPage;
    const endIndex = startIndex + this.state.itemsPerPage;
    const currentData = this.state?.Invoices?.slice(startIndex, endIndex);
    return (
      <div>
        <div>
          <AppHeader
            logoUrl={imgLogo}
            homeText="Home"
            createImageUrl={createImage}
            helpCenterText="Help Centre"
            userUrl={createImage}
            settingsIcon
            helpCenterNotificationUrl={imgNotification}
            onPressHome={() => this.homeNavigation()}
            onClickViewEnquiries={() => this.enquiryNavigation()}
            data-test-id="testAppheader"
            onClickViewProfile={() => this.profileNavigation()}
            onClickHelpCenter={() => this.helpCenterNavigation()}
            onClickSettings={() => this.settingsNavigation()}
            quoteUrl={group_plus}
            onClickQuote={() => this.quoteNaviagtion()}
          />
        </div>
        <div style={{ padding: "10px 40px 40px 40px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.6rem" , marginBottom:"-10px"}}>
            <p style={{ color: '#5A5A5A', fontSize: "14px", fontFamily: "CircularStd, sans-serif",cursor:"pointer" }} onClick={this.homeNavigation} data-test-id="testHome">Home</p>
            <img src={imgRightArrow} width={10} alt="imagRightArrow" />
            <p style={{ color: '#594FF5', fontSize: "16px", fontFamily: "CircularStdBold, sans-serif", }}>{configJSON.invoiceTex}</p>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div>
              <p style={{ fontSize: "30px", fontWeight: 700, fontFamily: "CircularStdBold" }}>{configJSON.invoiceTex} ({this.state?.Invoices?.length||0})</p>
            </div>
          </div>

          <div style={{background:"#ffffcc",border:"3px solid black",marginBottom:"5px"}}>
          <TableContainer component={Paper} style={{boxSizing :"border-box",paddingBottom:"60px", paddingLeft :"70px", paddingRight:"50px",paddingTop :"20px",minHeight:600}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{fontFamily:"CircularStdBold, sans-serif",fontSize:"16px",fontWeight:700,borderBottom:'none',paddingBottom :"40px"}}>Invoice Number</TableCell>
            <TableCell align="left" style={{fontFamily:"CircularStdBold, sans-serif",fontSize:"16px",fontWeight:700,borderBottom:'none',paddingBottom :"40px"}}>Event Name</TableCell>
            <TableCell align="left" style={{fontFamily:"CircularStdBold, sans-serif",fontSize:"16px",fontWeight:700,borderBottom:'none',paddingBottom :"40px"}}>Amount</TableCell>
            <TableCell align="left" style={{fontFamily:"CircularStdBold, sans-serif",fontSize:"16px",fontWeight:700,borderBottom:'none',paddingBottom :"40px"}}>Due Date</TableCell>
            <TableCell align="left" style={{fontFamily:"CircularStdBold, sans-serif",fontSize:"16px",fontWeight:700,borderBottom:'none',paddingBottom :"40px", display: "flex", alignItems: "center", cursor: "pointer"}} onClick={this.handleSortClick}><span>Created Date</span>
  {this.renderSortIcon(this.state.sortDirection)}</TableCell>
            <TableCell align="center" style={{fontFamily:"CircularStdBold, sans-serif",fontSize:"16px",fontWeight:700,borderBottom:'none',paddingBottom :"40px"}}>Archive</TableCell>
            <TableCell align="right" style={{fontFamily:"CircularStdBold, sans-serif",fontSize:"16px",fontWeight:700,borderBottom:'none',paddingBottom :"40px"}}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {this.state.Invoices?.length > 0 ? (
    currentData.map((row, index) => (
        <TableRow
            key={row.InvoiceID}
            className={index === currentData.length - 1 ? "lastRow" : ""}
        >
            <TableCell align="left" style={{ fontFamily: "CircularStd, sans-serif", fontSize: "14px", fontWeight: 450, }}>#{row.InvoiceNumber}</TableCell>
            <TableCell align="left" style={{ fontFamily: "CircularStd, sans-serif", fontSize: "14px", fontWeight: 450, }}> {row.Reference.replace('Inquiry #', '')}</TableCell>
            <TableCell align="left" style={{ fontFamily: "CircularStd, sans-serif", fontSize: "14px", fontWeight: 450, }}>$&nbsp;{row.Total}</TableCell>
            <TableCell align="left" style={{ fontFamily: "CircularStd, sans-serif", fontSize: "14px", fontWeight: 450, }}>{new Date(row.DueDateString).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })} | {row.Status === "AUTHORISED"
    ? "Outstanding"
    : row.Status.charAt(0).toUpperCase() + row.Status.slice(1).toLowerCase()}</TableCell>
<TableCell align="left" style={{ fontFamily: "CircularStd, sans-serif", fontSize: "14px", fontWeight: 450, }}>{new Date(row.DateString).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</TableCell>
            <TableCell data-testid={`download-${index}`} align="center" style={{ fontFamily: "CircularStd, sans-serif", fontSize: "14px", fontWeight: 450, textDecoration: "underline", cursor: "pointer", }} onClick={()=>this.fetchData(row.InvoiceID)}>
                Download
            </TableCell>
            
            <TableCell align="right" style={{ fontFamily: "CircularStd, sans-serif", fontSize: "14px", fontWeight: 450, cursor: "pointer", }}>
            <Tooltip title="Preview">
  <MoreVertIcon onClick={() => this.previewInvoices(row.InvoiceID)} />
</Tooltip>
            </TableCell>
        </TableRow>
    ))
) : (
    <p style={{paddingLeft:"5%"}}>No invoices available</p>
)}


        </TableBody>
      </Table>
    </TableContainer>
          </div>
          <div style={{ backgroundColor: "#FFFFFF", height: "64px", boxSizing: "border-box", padding: "15px", display: "flex", justifyContent: "space-between" }}>
    <div style={{ display: "flex", alignItems: "center", gap: "14rem", marginLeft: "auto", marginRight: "auto" }}>
              <Pagination 
              data-test-id="testPage"
    count={Math.ceil(this.state?.Invoices?.length / this.state.itemsPerPage)} 
    defaultPage={6} 
    siblingCount={0} 
    page={this.state.currentPage} 
    onChange={(event,  value) => { this.handlePageChange(value) }} 
    style={{ display: 'flex', justifyContent: 'center',fontFamily: 'CircularStd' }} 
    hidePrevButton={this.state.currentPage === 1} 
    hideNextButton={this.state.currentPage === Math.ceil(this.state?.Invoices?.length / this.state.itemsPerPage)} 
    renderItem={(item) => (
        <PaginationItem
          
            {...item}
            data-test-id="testPage1"
            component="div"
            style={{
                textDecoration: item.page !== this.state.currentPage ? 'underline' : 'none',
                color: item.page === this.state.currentPage ? '#594FF5' : 'inherit',
                backgroundColor: item.page === this.state.currentPage ? 'transparent' : 'inherit'
            }}
        />
    )}
/>
</div>
    <Box style={{
        fontSize: '12px',
        fontWeight: 'normal',
        color: '#5A5A5A',
        margin: '0',
        lineHeight: '1',
    }}>
        <p style={{ fontFamily: "CircularStd, sans-serif", fontSize: "14px", fontWeight: 400, color: "#5A5A5A", textAlign: "right" }}>
            1 - {Math.min(this.state.itemsPerPage, this.state?.Invoices?.length || 0)} of {this.state?.Invoices?.length || 0} results
        </p>
    </Box>
</div>
{this.renderPdfViewerDialog()}

        </div>
        {this.renderCompanySuspendedDialog()}
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const accountSuspendedModal = {
  container:{
    maxWidth: '100%', 
    width: '480px', 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: "center",
    border: '3px solid black', 
    boxSizing: 'border-box' as "border-box", 
    minHeight: '465px',
  },
  modalContentWrapper:{
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  crossMark:{
    backgroundColor: '#FF5B74',
    border: 'solid 3px #000',
    width: 'fit-content', 
    padding: '12px',
    marginBottom:12
  },
  crossMarkIcon:{
    height: '55px',
    color: 'black',
    width: '55px',
  },
  crossMarkCloseBtn:{
    position: "absolute" as "absolute", 
    top: '3px', 
    right: '3px'
  },
  crossMarkCloseIcon:{ 
    fontWeight: 900, 
    color: "black", 
    fontSize: "25x", 
    width: "25px", 
    height: "25px"
  },
  suspendedTitle:{
    fontSize:"clamp(1.5rem, 1.3917rem + 0.5333vw, 1.875rem)",
    color:"black",
    padding:10,
    fontWeight:700,
    fontFamily: 'CircularStd',
    marginBottom:14
  },
  suspendedMessage:{
    textAlign:"center" as "center",
    fontSize:"14px",
    fontFamily: 'CircularStd',
    color:"#5A5A5A",
    paddingInline:"20px"
  },
}

const webStyle = {
btnFilters:{
    fontSize: 14,
    fontWeight: 'normal',
    color: '#5A5A5A',
    borderRadius: 0,
    padding: '0 5px',
    fontFamily: 'CircularStd',
    textTransform: 'capitalize',
   
}as const,
btnFilters1:{
    borderRadius: 0,
    padding: '0 5px',
    fontSize: 14,
    fontFamily: 'CircularStd',
    textTransform: 'capitalize',
    fontWeight: 'normal',
    color: '#5A5A5A',
    borderLeft: '1px solid #000',
    borderRight: '1px solid #000',
}as const,

}
// Customizable Area End
