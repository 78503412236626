import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  TextField,
  FormControl,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Box,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { Formik } from "formik";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from "react-phone-input-2";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Text, View , Image} from "react-native";
import { createImage, imgLogo, imgNotification, imgRightArrow,group_plus,group_edit} from "./assets";
import AppHeader from "../../../components/src/AppHeader.web";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import * as Yup from "yup";
import { configJSON } from "./CustomisableUserProfilesController";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props
} from "./CustomisableUserProfilesController";
import { imgClose } from "../../categoriessubcategories/src/assets";
import GenericLabel from "../../../components/src/GenericLabel";
import { loadingImg } from "../../customform/src/assets";
// Customizable Area End

export default class EditProfile extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderSaveButton = (dirty: boolean) => {
    return <>
      <div className="buttonOutline" style={{ backgroundColor: (!dirty && !this.state.isUserProfileUploaded) ? '#ccc' : "" }}></div>
      <Button
        data-test-id="submitBtn"
        type="submit"
        className="btn-sm"
        style={{ backgroundColor: (!dirty && !this.state.isUserProfileUploaded) ? '#ccc' : "" }}
        disabled={!dirty && !this.state.isUserProfileUploaded}
      > Save Changes</Button>
    </>
  }

  render() {
    // Customizable Area Start
    const { isLoading } = this.state;
    return (
      <>
        <AppHeader
          logoUrl={imgLogo}
          homeText="Home"
          helpCenterNotificationUrl={imgNotification}
          createImageUrl={createImage}
          helpCenterText="Help Centre"
          settingsIcon
          userUrl={createImage}
          onClickViewEnquiries={
            () => this.goToEnquiry()}
          onClickViewProfile={() => this.goToProfile()
          }
          quoteUrl={group_plus}
          data-test-id="testAppHeader"
          onClickQuote={
            () => this.goToQuote()
          }
          onClickSettings={
            () => this.goToSettingsUser()
          }
          onClickHelpCenter={() => this.goToHelpCenterUser()}
          onPressHome={() => this.goToHome()}
          />
         <Container disableGutters maxWidth={false} style={{marginTop:30,paddingLeft:50,paddingRight:60,marginBottom:35}}>
              <Text style={{ color:'#5A5A5A', marginBottom: 40,fontFamily: "CircularStd, sans-serif",}} onPress ={this.goToHome} data-testid="goToHome">
                Home
              </Text>
              <img src={imgRightArrow} width={10} style={{marginLeft:10,marginRight:10}} alt="imagRightArrow"  />
              <Text style={{ color:'#5A5A5A', marginBottom: 40,fontFamily: "CircularStd, sans-serif",}} onPress={this.goToProfile}>
                Profile
              </Text>  
              <img src={imgRightArrow} width={10} style={{marginRight:10}} alt="imagRightArrow" />
              <Text style={{ color:'#594FF5',fontSize:14, marginBottom: 40,fontFamily: "CircularStdBold, sans-serif"}}>
                Edit Profile
              </Text>
            </Container>
            <Text style={{fontWeight:'600',fontFamily:"CircularStdBold",fontSize:30 ,top:48,paddingLeft:45}}> 
                     Edit Profile
                    </Text>
                    {isLoading ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',width:"100%", backgroundColor: 'transparent' }}>
       <img src={loadingImg}  style={{ paddingTop: "10%", display: 'flex', justifyContent: 'center', alignItems: 'center',height:"40%",width:"12%"}}/>
  </div>):(<View style={{paddingLeft:75,paddingRight:"130px", backgroundColor:'#F7F6FF'}}>
                <Grid container spacing={2} style={{alignItems:"center", display:"flex", justifyContent:"center"}} >
                  <Grid item xs={8} >
                  </Grid>
                  <Formik
                    initialValues={{
                      first_name: this.state.userProfileData,
                      last_name: this.state.userProfileDatalastname,
                      email: this.state.userProfileDataemail,
                      full_phone_number: this.state.userProfileDatamobile,
                      phoneNumber: this.state.userPhoneNumber,
                      jobTitle: this.state.userProfileDataJobTitle,
                      location: this.state.userProfileDatalocation
                    }}
                    validateOnMount={true}
                    validateOnChange={true}
                    validationSchema=
                    {Yup.object().shape(
                      this.state.updateProfileSchema
                    )}
                    enableReinitialize
                    onSubmit={(values, actions) => {
                      this.submitProfileDetails(values)
                      actions.setSubmitting(false);
                    }}
                    data-testid="contactForm"
                  >
                    {({ setFieldValue, handleBlur, values, handleSubmit, touched, errors, validateField, setFieldTouched, dirty }) => (
                      <form onSubmit={handleSubmit} style={{width:'100%', maxWidth:"1155px"}}>
                          
                  <Grid container xs={12} spacing={4} style={{ marginTop:23,}}>
  <Grid item xs={4} >
    <Typography
      style={{
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        gap: "32px",
        fontFamily: 'CircularStdBold'
      }}
    >
      User Type
    </Typography>
    <FormControl style={{width:"100%",height:36}} variant="outlined">
      <TextField
        data-test-id="role"
        className="customInput"
        disabled
        variant="outlined"
        inputProps={{
          style: {
            color: 'black',
            backgroundColor:"#D9D9D9"
          }
        }}
        name="Role"
        value={this.state.userProfileDatatype}
      />
    </FormControl>
  </Grid>

  <Grid item xs={4}>
    <Typography
      style={{
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        gap: "32px",
        fontFamily: 'CircularStdBold'
      }}
    >
      Company type
    </Typography>
    <FormControl style={{width:"100%",height:36}} variant="outlined">
      <TextField
        data-test-id="accountType"
        className="customInput"
        disabled
        variant="outlined"
        value={this.state.userPrfileDataAccountType}
        inputProps={{
          style: {
            color: 'black',
           backgroundColor:"#D9D9D9",
          textTransform:"capitalize"}
        }}
        name="Account Type"
      />
    </FormControl>
  </Grid>

  <Grid item xs={4}>
    <Typography
      style={{
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        gap: "32px",
        fontFamily: 'CircularStdBold'
      }}
    >
      Company
    </Typography>
    <FormControl style={{width:"100%",height:36}} variant="outlined">
      <TextField
        data-test-id="company"
        className="customInput"
        disabled
        variant="outlined"
        value={this.state.userProfileDatacompany}
        inputProps={{
          style: {
            width:'100%',
            color: 'black',
            backgroundColor:"#D9D9D9"
          }
        }}
        name="Company"
      />
    </FormControl>
  </Grid>
</Grid>
<div style={{backgroundColor: "#5A5A5A", marginTop:"40px",width:"calc(100% - 32px)",height:"1px", marginBottom:"34px"}}></div>

                        <Grid container direction="row" spacing={4} xs={12}>
                        <Grid item xs={4}>
                        <div style={{ position: 'relative', width: 200, height: 190, marginLeft: "auto", marginRight: "auto" }} onClick={this.openFileInput}>
                        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            border: '3px solid black',
          }} 
        ></div>
        <img
          src={this.selectProfile()}
          alt="profile"
          width="150"
          height="150"
          style={{
            width: 200, height: 190,
            border:"3px solid black",
          }}
          data-test-id="profileImage"
          onClick={this.openFileInput} 
        />
        <img
        src={group_edit}
    style={{
      position: 'absolute',
      top: '42%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      padding: '4px',
      color:"#fff",
    }}
  />
  <Typography
    variant="body1"
    style={{
      position: 'absolute',
      top: '60%',
      left: '62%',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 700,
      color:"#fff",
      width:150,
      lineHeight: '22px',
      fontFamily: 'CircularStdBold',
    }}
  >
    Change Picture
  </Typography>
         <input
                                        id="profilePhoto"
                                        type="file"
                                        ref={this.fileInput}
                                        onChange={this.handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                    </div>
    </Grid>

        <Grid item spacing={4} xs={8}>
        {((touched.first_name&&errors.first_name?.startsWith("Required:")) || (touched.last_name&&errors.last_name?.startsWith("Required:"))||(touched.email&&errors.email)||(touched.full_phone_number&&errors.phoneNumber) ||(touched.jobTitle&&errors.jobTitle) ||(touched.location&&errors.location)) &&   (
                          <Grid container spacing={4} style={{zIndex:9}} >
                          <Grid item xs={6}>
                          <AlertBox data-testid="alerMessage" style={{width:"100%", marginBottom:"16px"}}>
                             <div style={{ display: 'flex', flexDirection: 'row' }}>
                             <div>Feild Cannot be Empty</div> 
</div>
                          </AlertBox>
                          </Grid>
                          </Grid>
                        )}
          <Grid container spacing={4} style={{zIndex:9}} >
            <Grid item xs={6}>
              <Typography style={{ fontSize: '14px', fontWeight: 700, lineHeight: '22px', gap: '32px',fontFamily: 'CircularStdBold'}}>
                First Name*
              </Typography>
              <input style={{...webStyle.formInput,fontFamily: 'CircularStd', outline:"none"}} onBlur={handleBlur}  value={values.first_name} name="first_name" onChange={(event) => {setFieldValue('first_name',event.target.value);validateField('first_name');}}data-test-id="firstName" placeholder="FirstName" autoComplete="off"/>
              {errors.first_name?.startsWith("Max:") && <Grid container spacing={4} style={{zIndex:9}} >
                <Grid item xs={12}>
                  <AlertBox data-testid="alerMessage" style={{width:"100%", marginBottom:"16px"}}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>Please reduce your input to 20 characters or fewer.</div> 
                    </div>
                  </AlertBox>
                </Grid>
              </Grid>}
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontSize: '14px', fontWeight: 700, lineHeight: '22px', gap: '32px',fontFamily: 'CircularStdBold',}}>
                Last Name*
              </Typography>
                <input style={{...webStyle.formInput,fontFamily: 'CircularStd', outline:"none"}} onBlur={handleBlur}  value={values.last_name} name="last_name" onChange={(event) => {setFieldValue('last_name',event.target.value);validateField('last_name')}}data-test-id="lastName" placeholder="Last Name" autoComplete="off"/>
                {errors.last_name?.startsWith("Max:") && <Grid container spacing={4} style={{zIndex:9}} >
                <Grid item xs={12}>
                  <AlertBox data-testid="alerMessage" 
                  style={{ marginBottom:"16px", width:"100%", }}>
                    <div 
                    style={{ flexDirection: 'row', display: 'flex', }}>
                      <div>Please reduce your input to 20 characters or fewer.</div> 
                    </div>
                  </AlertBox>
                </Grid>
              </Grid>}
            </Grid>
          </Grid>
          <Grid container spacing={4}>
          <Grid item xs={6} style={{zIndex:4}}>
          <Typography
            style={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '22px',
              gap: '32px',
              fontFamily: 'CircularStdBold'
            }}
          >
            Email*
          </Typography>
              <input style={{...webStyle.formInput,fontFamily: 'CircularStd', outline:"none"}} onBlur={handleBlur}  value={values.email} name="email" onChange={(event) => {
  setFieldValue('email', event.target.value);
  this.handleChange(event);
  validateField('email')
}}data-test-id="email" placeholder="email@email.com" autoComplete="off"/>
              {this.state.errorFailureMessage.length > 0 && (
                <AlertBox data-testid="alertMessage" style={{marginLeft:0,width:"100%",marginBottom:"16px"}}>
                  {this.state.errorFailureMessage}
                </AlertBox>
              )}
        </Grid>
        <Grid item xs={6} style={{zIndex:4}}>
          <Typography
            style={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '22px',
              gap: '32px',
              fontFamily: 'CircularStdBold'
            }}
          >
            Mobile Number
          </Typography>
          <div style={{width:"100%"}}>
            <PhoneInput
              data-test-id="mobileNumber"
              country={'us'}
              value={values.full_phone_number}
              placeholder="Enter Your Number"
              onBlur={()=>setFieldTouched('full_phone_number', true)}
              onChange={(value, countryCode:{dialCode:string}) => {setFieldValue('full_phone_number',value); setFieldValue('phoneNumber',value.slice(countryCode.dialCode.length));}}
              inputStyle={{ border: '3px solid #000',borderRadius:0, width: '100%', height: '44px' ,fontFamily: 'CircularStd',paddingBottom:"8px",paddingTop:"8px",fontSize:"16px",outline:"none",boxShadow:"none"}}
              buttonStyle={{
                borderLeft: '3px solid #000',
                borderTop: '3px solid #000',
                borderBottom: '3px solid #000',
                backgroundColor:"#fff",
                borderRight:"0px",
                borderRadius:0,
                width:30,
              }}
              dropdownStyle={{ height: 100 ,textAlign:"center"}}
            />
            </div>
        </Grid> 
      </Grid>
      <Grid container spacing={4} style={{zIndex:9}} >
            <Grid item xs={6}>
              <Typography style={{ fontSize: '14px', fontWeight: 700, lineHeight: '22px', gap: '32px',fontFamily: 'CircularStdBold'}}>
                Job Title*
              </Typography>
              <input style={{...webStyle.formInput,fontFamily: 'CircularStd',outline:"none"}} onBlur={handleBlur}  value={values.jobTitle} name="jobTitle" onChange={(event) => {setFieldValue('jobTitle',event.target.value);validateField('jobTitle')}}data-test-id="jobTitle" placeholder="Job title" autoComplete="off"/>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontSize: '14px', fontWeight: 700, lineHeight: '22px', gap: '32px',fontFamily: 'CircularStdBold',}}>
                Location*
              </Typography>
                <input style={{...webStyle.formInput,fontFamily: 'CircularStd', outline:"none"}} onBlur={handleBlur}  value={values.location} name="location" onChange={(event) => {setFieldValue('location',event.target.value);validateField('location')}}data-test-id="location" placeholder="Location" autoComplete="off"/>
            </Grid>
          </Grid>
        </Grid>
      </Grid> 
      <div style={{width:"100%", display:"flex", gap:"32px", justifyContent:"end", marginTop:"70px", marginBottom:"60px", paddingRight:"32px",boxSizing:"border-box"}}>
                            <div className="buttonWrapper w-195 btn-yellow w-195" style={{width:199}}>
                              <div className="buttonOutline" style={{backgroundColor:"#FFC500"}}></div>
                              <Button
                                className="btn-sm"
                                onClick={this.goToProfile}
                              >Back to Profile</Button>
                            </div>
                              <div className={`buttonWrapper w-195 btn-blue`} style={{width:199}}>
                              {this.renderSaveButton(dirty)}
                              </div>
                        </div>
                      </form>
                    )}
                    </Formik>
                </Grid>

              <Dialog id="model_add" maxWidth="md"
                open={this.state.showModel} onClick={this.hideModal} 
                style={{border:'3px solid black'}} scroll="body">
                <ResponsiveDialogContent className="pt-100 pb-80" 
                style={{ alignItems:'center', width:'480px', minHeight:'448px',
                maxWidth:'100%', display: 'flex',border:'3px solid black', 
                boxSizing:"border-box", flexDirection: 'column'}}> 
                  <div className="closeWrapper" data-testid="hideModal" 
                  onClick={this.hideModal}  
                  style={{right:'20px', position:"absolute", cursor:"pointer", top:'20px',}}>
                    <Image source={ imgClose} resizeMode="cover" style={{ height:'20px', width:'20px',}}/>
                  </div>
                  <Box className="contentContainer" 
                  style={{display: 'flex', justifyContent: 'center', gap: '25px', 
                  flexDirection: 'column', alignItems: 'center'}}>
                    <Box className="contentImage" 
                    style={{border: 'solid 3px #000',width:'fit-content',
                    backgroundColor: '#00BFBA', padding: '10px',}}>
                      <CheckIcon style={{ fontSize: '48px',width:'60px', height:'60px', color: 'black'}} />
                    </Box>
                    <Typography className="heading" variant="h5" component="h2"style={{ fontSize: 30, color: "#000", marginBottom: "0", textAlign:'center', lineHeight: '44px', fontFamily:'CircularStdBold', backgroundColor: 'transparent',}}>Successfully Updated</Typography>
                  </Box>
                  <Typography
                  data-test-id="updatedSuccessMessage"
                   style={webStyle.modalMessageStyle}>{configJSON.SuccessModalMessage}</Typography>
                </ResponsiveDialogContent>
              </Dialog>

            <Dialog id="model_add" maxWidth="md"
                 open={this.state.showError} onClose={this.hideErrorModal} 
                 style={{
                  border:'3px solid black'
                  }}>
            <DialogContent 
            style={{ 
              alignItems:'center', 
              width:400,
               height:375, 
               border:'3px solid black'}}> 
            <div data-testid="hideModal" onClick={this.hideErrorModal} style={{position:"absolute",right:20,top:10,cursor:"pointer",}}>
              <Image source={imgClose} resizeMode="cover" style={{height:18,width:18,}}/>
            </div>
            <Box style={{
               border: '4px solid black', padding: '8px', backgroundColor: 'var(--Status-Red-600, #FF5B74)' , 
               height:63,width:65,display: "flex",
flexDirection: "column",alignItems: "center",
position:'absolute', top:'22%',left:"180px"}}>
<ClearIcon style={{ fontSize: '48px', 
color: 'black' , height:'60px',width:'60px'}} />
<Typography variant="h5"component="h2"style={{
	 fontSize: 30,color: "#000",
	 justifyContent: "center", marginBottom: "20px",
	 display: "flex", alignItems: "center", 
	 fontFamily:'CircularStdBold',
	 marginTop:30, width:345,backgroundColor:"#fff"
   }}
 >
   Invalid Email
 </Typography>
 <GenericLabel color="#5A5A5A" fontFamily='CircularStd' align="center"  fontSize={14} lineHeight={19} pr={15} width='350px' mt={10}>The email address you have provided is either invalid or already in use on the platform.</GenericLabel>
</Box>
            
              </DialogContent>
            </Dialog> 
            <Dialog id="model_add" maxWidth="md"
                 open={this.state.showWaring} onClose={this.hideWarningModal} 
                 style={{
                  border:'3px solid black'
                  }}>
            <DialogContent 
            style={{ 
              alignItems:'center', 
              width:400,
               height:420, 
               border:'3px solid black'}}> 
            <div data-testid="hideModal" 
            onClick={this.hideWarningModal} style={{position:"absolute",right:20,top:10,cursor:"pointer",}}>
              <Image 
                source={imgClose}
                resizeMode="cover"style={{height:18,width:18,}}
              />
            </div>
            <Box style={{
               border: '4px solid black', padding: '20px', 
               backgroundColor: 'var(--Status-Amber-400, #FFBA00)' , 
               height:83,width:83,display: "flex",flexDirection: "column",alignItems: "center",position:'absolute', top:'10%',left:"165px"}}>
  <Typography
   variant="h5"
   component="h2"
   style={{
	 fontSize: 88,color: "#000",
	 justifyContent: "center", backgroundColor:"var(--Status-Amber-400, #FFBA00)",
	 display: "flex", alignItems: "center", 
	 fontFamily:'CircularStdBold',
	 marginTop:-15
   }}
 >?</Typography>

<Typography
   variant="h5"
   component="h2"
   style={{
	 fontSize: 30,color: "#000",
	 justifyContent: "center", marginBottom: "20px",
	 display: "flex", alignItems: "center", 
	 fontFamily:'CircularStdBold',
	 marginTop:35, width:345,backgroundColor:"#fff"
   }}
 >
   Confirmation Required
 </Typography>
 <GenericLabel color="#5A5A5A" fontFamily='CircularStd' align="center"  fontSize={14} lineHeight={19} pr={15} width="350px">The email address you have submitted is not within your company domain.</GenericLabel>
 <GenericLabel color="#5A5A5A" fontFamily='CircularStd' align="center"  fontSize={14} lineHeight={19} pr={15} width='350px' mt={10}>Your company administrator will be notified of this change if you proceed.</GenericLabel>
</Box>
<div style={{ display: 'flex', marginTop: 350, marginLeft:'30%'}}>
                          <div data-testid="hideModalText" onClick={this.hideWarningModal} style={{marginTop:10,cursor:"pointer",textAlign:"center"}}>
              <Text style={{textDecorationStyle:"solid",textDecorationLine:"underline", color:"#000" ,fontFamily:'CircularStd',fontSize:16 }}>Cancel</Text> 
            </div>
                         
                            <View>
                            <div className="buttonWrapper btn-green w-390" style={{marginLeft:20, width:120}}>
                              <div className="buttonOutline" style={{backgroundColor:"#00BFBB"}}></div>
                                <Button
                                  data-test-id="confirmBtn"
                                  type="submit"
                                  className="btn-sm w-195" 
                                  onClick={()=>this.confrimProfileDetails()}
                                >Confirm</Button>
                              </div>
                            </View>
                            </div>
            
              </DialogContent>
            </Dialog>
              </View>)}
            
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const AlertBox = styled("div")({
  marginTop:5,
  border:0,
  borderLeft:4,
  boxSizing:'border-box',
  borderTopLeftRadius:5,
  borderBottomLeftRadius:5,
  fontSize:14,
  fontFamily: 'CircularStd',
  padding:20,
  backgroundColor: "#FEE2E2",
  borderLeftColor:"#FF5B74",
  borderStyle:"solid",
  marginBottom:30,
  color:"#FF5B74",
  width:'60%',
});
const ResponsiveDialogContent = styled(DialogContent)({
  '&.pb-80':{
    paddingBottom:'80px'
  },
  '&.pt-100':{
    paddingTop: '100px',
  },
  '@media only screen and (max-width:500px)':{
    width: '390px !important',
    minHeight:'340px !important',
    gap: '20px !important',
    '&.pt-100':{
      paddingTop: '80px',
    },
    '&.pb-35,&.pb-80':{
      paddingBottom: '40px'
    },
    '& .closeWrapper':{
      right: '15px !important',
      top: '15px !important'
    },
    '& .closeWrapper >div':{
      height: '18px !important',
      width: '18px !important'
    },
    '& .contentContainer':{
      gap: '18px !important'
    },
    '& .contentImage':{
      padding: '7px !important'
    },
    '& .contentImage >svg':{
      height: '35px !important',
      width: '35px !important'
    },
    '& .calendarImage >div':{
      height: '30px !important',
      width: '30px !important'
    },
    '& .calendarImage':{
      padding: '12px !important'
    },
    '& .heading':{
      fontSize:'24px !important',
      lineHeight: '36px !important'
    },
    '& .text':{
      fontSize:'14px !important',
      lineHeight: '23px !important',
      marginTop: '5px !important',
      maxWidth: '330px !important'
    },
    '& .icalendar-select':{
      maxWidth:'200px !important'
    },
    '& .ContinueButton':{
      width: '150px !important',
      marginBottom: '15px !important'
    },
    '& .warningLogo':{
      width: '60px !important',
      height: '60px !important'
    },
    '& .warningLogo >h2':{
      fontSize: '40px !important'
    },
    '& .proceedButton':{
      marginTop: "20px"
    },
    '& .proceedButton >div>div':{
      width: '100px !important'
    }
  }
})
const webStyle = {
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  modalMessageStyle: {
    fontFamily:'CircularStd',
    fontWeight: 400,
    marginTop:40,
    color:"#5A5A5A"
  },
  formInput: 
  { width: "100%", border: "3px solid black", height: "42px", padding: "8px", marginBottom: "10px" , fontSize:"16px" }
};
// Customizable Area End
