export const imgLogo = require("../assets/logo.png");
export const imgUser = require("../assets/1043817461cFxzonYRDJMRVgjEbjIYjo.png");
export const imgNotification = require("../assets/1043817464cFxzonYRDJMRVgjEbjIYjo.png");
export const createImage = require("../assets/1043817466cFxzonYRDJMRVgjEbjIYjo.png");
export const imgRightArrow = require("../assets/arrow.svg");
export const imgClock = require("../assets/clock.png");
export const imgRequestQuoteBg = require("../assets/request_quote.png");
export const imgEnquire = require("../assets/enquire_bg.png");
export const downArrow = require("../assets/down_Arrow.png");
export const group_plus = require("../assets/group_plus.svg");
export const closeBtnIcon = require("../assets/ClosebtnIcon.png")
