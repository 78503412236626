import React from "react";
// Customizable Area Start
import { Text, View } from "react-native";
import { Container, Button, Table, Typography, TableHead, TableRow, TableCell, TableBody, TableContainer, Box, Dialog, DialogContent, Grid, TextField, MenuItem, Select ,Tooltip, styled,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import AppHeader from "../../../components/src/AppHeader.web";
import CustomFormController, {
  Props,
  configJSON,
  baseURL
} from "./CustomFormController.web";
import {
  imgLogo,
  createImage,
  imgNotification,
  imgRightArrow,
 group_plus,
 loadingImg,
 imgClose,
 appleicon,
 googlecalicon,
 windowscalicon,
 yahooIcon,
 groupmeeticon,
 calendaricon,downloadImage
} from "./assets";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Image, StyleSheet } from "react-native";
import ClearIcon from '@material-ui/icons/Clear';
import GenericLabel from "../../../components/src/GenericLabel";
import CheckIcon from '@material-ui/icons/Check';
import GetAppIcon from '@material-ui/icons/GetApp';
import ICalendarLink from "react-icalendar-link";import moment from "moment";
import { Redirect } from 'react-router-dom'

// Customizable Area End

export default class Enquiries extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderDate = (data: string | null) => {
    return data ? (
      <>
        {data}
      </>
    ) : (
      " "
    )
  };

  renderSortIcon = (sortDirection:any) => {
    if (sortDirection === 'asc') {
      return <KeyboardArrowUpIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
    } else if (sortDirection === 'desc') {
      return <KeyboardArrowDownIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
    }
    return null;
  };
  renderViewCustomFormPopupPriceDetails() { 
    return (
      <View style={{ backgroundColor: '#DEDCFD', 
      borderColor: '#000', borderWidth: 3, padding: 20, paddingTop: 10, paddingBottom: 10, width: 450, marginRight: "20px", maxWidth:"calc(100% - 20px)"}}>
        <Text style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 14, paddingBottom: 20 }}>
        {this.state?.EnquiryData?.attributes 
          && this.state?.EnquiryData?.attributes?.service_name}
        </Text>
        <View style={[styles.tableProvision, 
          { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text style=
          {styles.fontSm}>
            Provisional Cost</Text>
          <Text 
          style={styles.fontSm}>£
          {this.state.EnquiryData?.attributes?.cost_summery?.provisional_cost}</Text>
        </View>
        <View 
        style={[styles.tableProvision,
         { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text style={styles.fontSm}>
            Inc. Add-ons</Text>
          <Text 
          style={styles.fontSm}>
            £{this.state.EnquiryData?.attributes?.cost_summery?.provisional_addon_cost}</Text>
        </View>
        <View 
        style={[styles.tableProvision, { borderBottomColor: '#CBD5E1', 
        borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text 
          style={styles.fontSm}>Additional Services</Text>
          <Text 
          style={styles.fontSm}>
            £{this.state.EnquiryData?.attributes?.cost_summery?.additional_services_cost}</Text>
        </View>
        <View 
        style={[styles.tableProvision, { 
          borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text 
          style={styles.fontSm}>
            Inc. Add-ons</Text>
          <Text 
          style={styles.fontSm}>£
          {this.state.EnquiryData?.attributes?.cost_summery?.additional_addons_cost}</Text>
        </View>
        <View style={[styles.tableProvision, 
          { paddingTop: 10 }]}>
          <Text style={{
             fontFamily: "CircularStdBold",
              fontSize: 16,
               color: '#000' }}>{configJSON.subTotal}</Text>
          <Text
           style={{ 
            fontFamily: "CircularStdBold", 
            fontSize: 16, 
            color: '#000' }}>
              £{this.state.EnquiryData?.attributes?.cost_summery?.sub_total}</Text>
        </View>
      </View>
    );
  }

  renderTooltipMessage = (isDisabled : boolean ) => {
    return isDisabled ? "LF Admin approval is required" : "";}

  renderCommonButtons() {
    const role = localStorage.getItem("role");
    const customFormStatus = this.state.EnquiryData && this.state.EnquiryData.attributes && this.state.EnquiryData.attributes.status;
    const isPending = customFormStatus === "pending";
    const isApproved = customFormStatus === "approved";
    const isRejected = customFormStatus === "rejected";
    const isDraft = customFormStatus === "draft";
    const isClientAdmin = role === "ClientAdmin";
    const isClientUser = role === "ClientUser";


    const isDraftButton = ()=>{
      return (
        <Tooltip title={this.renderTooltipMessage(!this.state.EnquiryData.attributes.is_service)} placement="top">
          <div className="buttonWrapper" style={{ marginRight: 20 }}>
            <Button data-testid="editEnquiry" onClick={this.editEnquiry} style={{fontFamily: 'CircularStdBold', border:'none', height: '100%', width:'100%',textDecoration: 'none',
              fontSize: '16px',
              fontWeight: 500,
              fontStyle: 'normal',
              textTransform: 'none',
              lineHeight: 'normal'}}
              disabled={!this.state.EnquiryData.attributes.is_service}
              ><p style={{ borderBottom: "solid 2px #000"}}>Edit Enquiry</p>
            </Button>
          </div>
        </Tooltip>
      )
    }

    const scheduleMeetButton = () =>{
      const isDisabled = isClientAdmin && isRejected || isClientUser && isRejected || isClientUser && isPending
      return(
        <Tooltip title={isDisabled ? "You cannot make changes to this enquiry as this package/service has been deactivated for your organisation." : ""} placement="top">
          <div className="buttonWrapper btn-green w-195" 
          style={{marginRight: 20 }}>
            <div className="buttonOutline" 
            style={{ backgroundColor: isDisabled? '#ccc' : '#00BFBB' }}
            ></div>
            <Button
              className="btn-sm"
              style={{
                height: 41,
                fontSize: 16,
                backgroundColor: isDisabled? '#ccc' : ''
              }}
              onClick={this.meetLink}
              disabled={isDisabled}
            >
              Schedule Meeting
            </Button>
          </div>
        </Tooltip>
      )
    }

    const submitButton = () =>{
      return(
        <Tooltip title={!this.state.is_service ? "You cannot make changes to this enquiry as this package/service has been deactivated for your organisation." : ""} placement="top">
          <div className="buttonWrapper w-195 btn-blue" style={{ backgroundColor: !this.state.is_service ? '#ccc' : ''}} >
            <div className="buttonOutline" style={{ backgroundColor: !this.state.is_service ? '#ccc' : '' }}></div>
            <Button type="submit" style={{ backgroundColor: !this.state.is_service ? '#ccc' : '' }} className="btn-sm w-195" onClick={this.submitForm} disabled={!this.state.is_service}>
              Submit
            </Button>
          </div>
        </Tooltip>
      )
    }
  
    return (
      <>
        {(isApproved && isClientAdmin  || isRejected && isClientAdmin || isPending && isClientAdmin )&& (
          <div className="buttonWrapper w-195 btn-yellow w-195" style={{ marginRight: 20 }}>
            <div className="buttonOutline" style={{ backgroundColor: "#FFC500" }}></div>
            <Button data-testid="resetButton" className="btn-sm" onClick={this.goToHome}>Back to Home</Button>
          </div>
        )}

        { isDraft && isDraftButton() }
  
        {this.renderDownloadButton()}
  
        {(isPending || isApproved  || isRejected) && scheduleMeetButton() }
  
             {(isApproved && isClientAdmin  || isRejected && isClientAdmin || isClientUser && isApproved || isClientUser && isPending || isClientUser && isRejected)&& (
          this.renderCalendar(isClientAdmin,isRejected,isPending,isClientUser)
        )}
        
        {isDraft && submitButton() }
        {this.renderApproveRejectButtons()}
      </>
    );
  }
  
    renderDownloadButton() {
      return (
        <>
         
            <div className={`buttonWrapper w-220 btn-subcategory3`} style={{ marginRight: 20 }}>
              <div className="buttonOutline" style={{ backgroundColor: "#FF5B74" }}></div>            
              {this.state.errorCell && <a href={baseURL+"/download_pdf/"+this.state.enquiryDetails.id} download>
                <Button type="submit" className="btn-sm w-195">Download</Button>
                </a>}
            </div>
        
        </>
      );
    }
    renderCalendar(isClientAdmin:any,isRejected:any,isPending:any,isClientUser:any){
      const isDisabled = isClientAdmin && isRejected || isClientUser && isRejected || isClientUser && isPending
        return(
          <Tooltip title={!this.state.is_service ? "You cannot make changes to this enquiry as this package/service has been deactivated for your organisation." : ""} placement="top">
          <div 
          className="buttonWrapper w-195 btn-blue"
           style={{ backgroundColor: isDisabled ? '#ccc' : '' ,marginRight: 20 }}
           >
          <div 
          className="buttonOutline"
          style={{ backgroundColor: isDisabled ? '#ccc' : '' }}
          ></div>
            <Button
              type="submit"
              className="btn-sm w-195"
              style={{
                backgroundColor: isDisabled ? '#ccc' : '', 
              }}
              disabled={isDisabled}
              onClick={this.goToiCalendar}
            >
              iCalendar
            </Button>
          </div>
          </Tooltip>
          )
      }

      renderButtonBackGroundColor = (isbuttonDisable:boolean) => {
       return isbuttonDisable? '#ccc' : ''
      }

      renderButtonOutLineBackGroundColor = (isbuttonDisable:boolean) => {
        return isbuttonDisable ? '#ccc' : "#FF5B74" 
      }

      renderApproveBtnOutlineBgColor = (isbuttonDisable:boolean) => {
        return isbuttonDisable ? '#ccc' : "#00BFBB" 
      }
      
      renderApproveRejectButtons() {
        const role = localStorage.getItem("role");
         const isPending = this.state.EnquiryData && this.state.EnquiryData.attributes && this.state.EnquiryData.attributes.actual_status === 'pending'
        const isAdminApproved = this.state.EnquiryData && this.state.EnquiryData.attributes && this.state.EnquiryData.attributes.lf_admin_approval_required === true
        const isbuttonDisable = isPending && isAdminApproved
        const rejectButton = () =>{
          return (
            <Tooltip title={this.renderTooltipMessage(isbuttonDisable)} placement="top">
              <div className={`buttonWrapper w-220 btn-subcategory3`} style={{ marginRight: 20 }}>
              <div className="buttonOutline" style={{ backgroundColor: this.renderButtonOutLineBackGroundColor(isbuttonDisable)}}></div>
                <Button data-test-id="approveRejectBtn" className="btn-sm w-195" onClick={this.rejectApiFN} 
                    style={{ backgroundColor: this.renderButtonBackGroundColor(isbuttonDisable)}} 
                    disabled={isbuttonDisable}>
                      Reject
                </Button>
              </div>
            </Tooltip>
          )
        }

        const approveButton = () =>{
          return (
            <Tooltip title={this.renderTooltipMessage(isbuttonDisable)} placement="top">
              <div className="buttonWrapper btn-green w-195">
              <div className="buttonOutline" style={{ backgroundColor: this.renderApproveBtnOutlineBgColor(isbuttonDisable)}}></div>
                  <Button data-test-id="approveRejectBtn" className="btn-sm w-195" onClick={this.approveApiFN} 
                    style={{ backgroundColor: this.renderButtonBackGroundColor(isbuttonDisable)}} 
                    disabled={isbuttonDisable}>
                    Approve
                  </Button>
              </div>
            </Tooltip>
          )
        }
        if (role !== "ClientAdmin") return null;

        if (this.state?.EnquiryData?.attributes?.status === "pending") {
          return (
            <>
              {rejectButton()}
              {approveButton()}
            </>
        );
    }
    }
    renderCalndarType(){
      const {showOption} = this.state
      return(
        <Select
        data-testid="calendarType"
        className="multiselect-label"
        placeholder="Add to My Calendar"
        defaultValue={"Add to My Calender"}
        value={this.state.calendarType}
        onChange={(event)=>this.handleCalendarTypeChange(event)}
        style={{ backgroundColor: '#fff', color: '#000', fontFamily: 'CircularStd',width:300,height:42 }}
        inputProps={{
          style:{
            color:"#000"
          }
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'menu',
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              border: '3px solid black',
              borderTop: 0,
              margin: '0px',
              boxShadow: 'none',
              borderRadius: 0,
            },
          },
        }}
      >
        <MenuItem value="apple" className="muliselect-custom-menu"><img src={appleicon} alt="apple calender" style={{ marginRight: '5px',height: "14px",
      paddingBottom: "4px" }}/>Apple Calendar</MenuItem>
        <MenuItem value="google" className="muliselect-custom-menu"><img src={googlecalicon} alt="google calender" style={{  marginRight: '5px',height: "14px",
      paddingBottom: "4px" }}/>Google Calendar</MenuItem>
        <MenuItem value="outlook" className="muliselect-custom-menu"><img src={windowscalicon} alt="outlook calender" style={{  marginRight: '5px',height: "14px",
      paddingBottom: "4px" }}/>Outlook</MenuItem>
        <MenuItem value="yahoo" className="muliselect-custom-menu"><img src={yahooIcon} alt="yahoo icon" style={{  marginRight: '5px',height: "14px",
      paddingBottom: "4px" }}/>Yahoo</MenuItem>
        <MenuItem value="download" className="muliselect-custom-menu"><GetAppIcon style={{ height: "16px", marginLeft:"-6px"}}/>Download (.ics)</MenuItem>
        <MenuItem value="calendarIcon" className="muliselect-custom-menu" style={{ display: showOption ? 'block' : 'none' }}><img src={calendaricon} alt="yahoo icon" style={{  marginRight: '5px',height: "14px",
      paddingBottom: "4px" }}/>Add to My Calendar</MenuItem>
      </Select>
      )
    }

    renderCalendarButton(){
      return (<>{(
        this.state.calendarType === 'download' || this.state.calendarType === 'apple'
        ) ? ( <ICalendarLink  className="btn-sm w-150" event={this.state.eventDetails}>Continue</ICalendarLink> ) : ( "Continue" )}
        </>)
    }

    renderDownload(attributes:any){
      return (
        <div>
          {attributes?.files?.length > 0 ?
            <Box sx={webStyle.gridViewFiles} >
              {attributes.files.map((file: { url: string, name: string, id: string }) => (
                <Box key={file.id} sx={webStyle.gridFileWrapper}>
                  <Typography style={webStyle.filename}>{this.getFileName(file.name)}</Typography>
                  <img style={webStyle.fileDownloadBtn} onClick={() => this.downloadFile(file.name, file.url)} src={downloadImage} />
                </Box>
              ))
              }
            </Box>
            : (
              <Text style={webStyle.noFileAddedTxt}>{configJSON.noFilesAdded}</Text>
            )}
        </div>
      )
    }

    isAccessDenied = (companyId: any, stateCompanyId:any, role: any) => {
      return (
        (stateCompanyId && companyId !== stateCompanyId.toString() && role === "ClientAdmin") ||
        role === "ClientUser"
      );
    };

    renderAccessDenied = () => {
      return <Redirect to="/AccessDenied" />;
    }

    renderLoadingSpinner = (loadingImg: any) => {
      return  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',width:"100%", backgroundColor: 'transparent' }}>
      <img src={loadingImg}  style={{height:"50%",width:"25%"}}/>
 </div>
    }

    renderContent = (isAccessDenied: any, isLoading: any, loadingImg: any) => {
      if (isAccessDenied) {
        return this.renderAccessDenied();
      }
    
      if (isLoading) {
        return this.renderLoadingSpinner(loadingImg);
      }
    }

    renderTimeString = (name: any, value: any) =>{
      if(name == "Event Start Time" || name == "Event End Time"){
        return this.getTimeString(value);
      }else {
          return
      }
    }

    renderValue = (name: any, value: any) => {
      if(name == "Event Date"){
        return value
      }else {
        return
      }
    }

    renderTitle = (userInput: string): string => {
      return userInput.length > 28 ? userInput : "";
    };

    renderGridItem = (input: any, index: number, getTooltipProps:any, reviewEventStartTime:any) => {
      if (!input?.attributes?.user_input) return null;
     
      return <Grid item xs={12} sm={6} md={3} key={index}>
              <div style={{ marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold" }}>
                {input.attributes.input_field.name}
              </div>
              <Tooltip
                data-testid="inputToolTip"
                title={getTooltipProps(input).title}
                placement="top"
                arrow
                disableHoverListener={getTooltipProps(input).disableHoverListener}
              >
                <TextField
                  className="customInput"
                  inputProps={{ style: { backgroundColor: "#fff", color: "#000" } }}
                  value={reviewEventStartTime(input.attributes.input_field.name, input.attributes.user_input)}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Tooltip>
            </Grid>
    }

    renderInputGrid = (inputValues: any[], getTooltipProps:any, reviewEventStartTime:any) => {
      return inputValues.map((input: any, index: number) => this.renderGridItem(input, index, getTooltipProps, reviewEventStartTime));
    };

    renderEnquiryData = (enquiryData: any, getTooltipProps:any, reviewEventStartTime:any) => {
      const inputValues = enquiryData?.attributes?.base_service_detail?.data?.attributes?.input_values?.data || [];
      return this.renderInputGrid(inputValues, getTooltipProps, reviewEventStartTime);
    }

    renderCategoryAll = (category: any) => {
      if(category === 'all enquiries'){ return 'active'} else return '';
    }

    renderCategoryApproved = (category: any) => {
      if(category === 'approved enquiries'){ return 'active'} else return '';
    }

    renderCategoryPending = (category: any) => {
      if(category === 'pending enquiries'){ return 'active'} else return '';
    }

    renderCategoryDraft = (category: any) => {
      if(category === 'draft enquiries'){ return 'active'} else return '';
    }

    renderSubtotal = (attributes: any) => {
      if(!attributes.cost_summery) return null;
      return  attributes.cost_summery.sub_total
    }

    getItemStyle = (itemPage: any, currentPage: any) => {
      return {
        textDecoration: itemPage !== currentPage ? "underline" : "none",
        color: itemPage === currentPage ? "#594FF5" : "inherit",
        backgroundColor: itemPage === currentPage ? "transparent" : "inherit",
      };
    };

    getResultsText = (
      inquiriesDataLength: any,
      indexOfFirstRecord: any,
      indexOfLastRecord: any
    ) => {
      if (inquiriesDataLength === 0) {
        return "0 - 0 of 0 results";
      }
    
      const start = indexOfFirstRecord + 1;
      const end = Math.min(indexOfLastRecord, inquiriesDataLength);
    
      return `${start} - ${end} of ${inquiriesDataLength} results`;
    };

    renderStatus = () => {
      if(!this.state?.EnquiryData?.attributes) return;
      return <Text style={{ textAlign : "center",fontFamily:"CircularStd" }}>
      {this.state.EnquiryData.attributes?.status?.charAt(0).toUpperCase() + this.state.EnquiryData.attributes?.status.slice(1)}
    </Text>
    }

    renderGroupByAdditionalAddon = () => {
      if(!this.state.groupByAdditionalAddon) return null;
      return this.state.groupByAdditionalAddon.map((service: any) => (
        service.attributes.input_values.data.map((input: any, index: any) => (
            input.attributes.user_input && (
                <Grid item xs={3} 
                key={index}>
                    <div style={
                      { marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold" }}>
                        {input.attributes.input_field.name}
                    </div>
                    <TextField
                        className="customInput"
                        inputProps={{ style: 
                          { backgroundColor: "#fff", color: "#000" } }}
                        value={
                          input.attributes.user_input}
                        variant="outlined" 
                        fullWidth disabled />
                </Grid>
            )
        ))
    ))
    }

    renderNote = () => {
      if(!this.state.customForm.attributes?.note) return;
      return <Grid item xs={12} style={{
        flexDirection:"column", display:"flex", boxSizing:"border-box", justifyContent:"flex-end",}}>
        <div style={
          { fontFamily: "CircularStdBold", fontSize: "13px", marginBottom: "5px"}}>
            {configJSON.tellUsProject}
        </div>
        <Tooltip placement="top" title={this.state.customForm.attributes?.note}>
          <TextField className="overflowFieldsEllipsis customInput "
            inputProps={{ style: {  color: "#000", backgroundColor: "#fff",}}}
            value={this.state.customForm.attributes.note} variant="outlined" fullWidth disabled />
        </Tooltip>
      </Grid>
    }

    renderBespokeTypography = () => {
      if(!this.state.isBespokeRequest) {
        return <>
          <Typography variant="h5" component="h2"style={{ fontSize: 30, marginBottom: "0", 
          fontFamily:'CircularStdBold', color: "#000", lineHeight: '44px',
          backgroundColor: 'transparent', textAlign:'center',}}>Successfully Submitted</Typography>
          <Text style={{ marginTop:'10px', textAlign: 'center',
            lineHeight: 28, color:"#5A5A5A", fontFamily:'CircularStd', 
            fontSize: 16, maxWidth: 380}}>Thanks for submitting your enquiry. It has been successfully submitted to your company Admin for approval.</Text>
      </>} else {
          return <>
          <Typography variant="h5" component="h2"style={{ fontSize: 30,color: "#000", 
          lineHeight: '44px', backgroundColor: 'transparent',
          marginBottom: "0", fontFamily:'CircularStdBold', textAlign:'center'}}>Successfully Submitted to London Filmed</Typography>
          <Text style={{color:"#5A5A5A", 
          fontFamily:'CircularStd', maxWidth: 440, textAlign: 'center', 
          fontSize: 16, lineHeight: 28,}}>Thanks for submitting your enquiry. It has been successfully submitted to your London Filmed Account Manager for review.</Text>
          <Text style={{color:"#5A5A5A", lineHeight: 28, fontSize: 16, 
          marginTop:'10px', maxWidth: 440, 
          fontFamily:'CircularStd', textAlign: 'center'}}>Your Account Manager will be in touch shortly with your quote or to contact you for more information.</Text>
      </>}
    }
  // Customizable Area End

  render() {

    // Customizable Area Start
    const {category, categoryList, currentPage, recordsPerPage ,isLoading, company_id} = this.state
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const { data: inquiriesData = [] } = categoryList.inquiries || {};
    const currentRecords = inquiriesData.slice(indexOfFirstRecord, indexOfLastRecord);
    const companyId = localStorage.getItem('companyId');
    const role = localStorage.getItem('role');
    
    const accessDenied = this.isAccessDenied(companyId, company_id, role);

    this.renderContent(accessDenied, isLoading, loadingImg);
    
    const reviewEventStartTime = (name: any, value: any): unknown => {
        this.renderTimeString(name, value)
        this.renderValue(name, value)
        return this.getInputName(value);
    }
    const getTooltipProps = (input:any) => {
      const userInput = input.attributes.user_input;
      return {
          title: this.renderTitle(userInput),
          disableHoverListener: userInput.length <= 28,
      };
  };

    // Customizable Area End

    return (
      // Customizable Area Start
      <Box>
        <Container disableGutters maxWidth={false} className="main-container-1">
                <AppHeader
                    logoUrl={imgLogo}
                    homeText="Home"
                    createImageUrl={createImage}
                    helpCenterText="Help Centre"
                    userUrl={
                        createImage}
                    settingsIcon
                    quoteUrl={group_plus}
                    helpCenterNotificationUrl={imgNotification}
                    onPressHome={() => this.goToHome()}
                    onClickViewProfile={
                        () => this.goToProfileCustom()
                    }
                    onClickHelpCenter={() => 
                        this.goToHelpCenterCustom()}
                    onClickViewEnquiries={() => this.goToEnquiryCustom()
                    }
                    onClickSettings={() => 
                        this.goToSettingsCustom()}
                    onClickQuote={() => this.goToQuoteCustom()}
                    data-test-id="testAppHeader"

                />
          <View
            style={{
                paddingTop: 40,
                paddingBottom: 40,
                
            }}
          >
            <Container disableGutters maxWidth={false} style={{paddingLeft:45,paddingRight:60,marginBottom:35}}>
                <Text style={{ color:'#5A5A5A', marginBottom: 40,fontFamily: "CircularStd, sans-serif",}} onPress={this.goToHome} data-testid="gotoHome">
                {configJSON.homeText1}
                </Text> 
                <img src={imgRightArrow} width={10} style={{marginLeft:10,marginRight:10}} alt="imagRightArrow" />
                <Text style={webStyle.contegoryTxt}>
                {category}
                </Text>
            </Container>
            <Container disableGutters maxWidth={false} style={{paddingLeft:25,paddingRight:25,marginBottom:35}}>
                <View style={{ flex:1,height:'100%'}}>
                    <Box sx={enqTabCss}>
                        <div data-testid="allenquiries" className={`buttonWrapper w-195 btn-blue ${this.renderCategoryAll(category)}`} style={{marginRight:25}} onClick={() => this.setCategoryTxt("all enquiries")}>
                            <div className="buttonOutline"></div>
                            <Button
                            className="btn-sm" 
                            >{configJSON.allenquiries}</Button>
                        </div>
                        <div data-testid="approvedEnq"  className={`buttonWrapper w-220 btn-green ${this.renderCategoryApproved(category)}`} style={{marginRight:25}} onClick={() => this.setCategoryTxt("approved enquiries")}>
                            <div className="buttonOutline" style={{backgroundColor:"#00BFBB"}}></div>
                            <Button
                            className="btn-sm" 
                            > {configJSON.approvedEnq}</Button>
                        </div>
                        <div data-testid="pendingEnq"  className={`buttonWrapper w-220 btn-gray ${this.renderCategoryPending(category)}`} style={{marginRight:25, backgroundColor:"#D3D3D3"}} onClick={() => this.setCategoryTxt("pending enquiries")}>
                            <div className="buttonOutline" style={{backgroundColor:"#5A5A5A"}}></div>
                            <Button
                            className="btn-sm" 
                            > {configJSON.pendingEnq}</Button>
                        </div>
                        <div data-testid="draftEnq"  className={`buttonWrapper w-195 btn-yellow ${this.renderCategoryDraft(category)}`} style={{marginRight:25}} onClick={() => this.setCategoryTxt("draft enquiries")}>
                            <div className="buttonOutline" style={{backgroundColor:"#FFC500"}}></div>
                            <Button
                            className="btn-sm"
                            >{
                            configJSON.draftEnq} </Button>
                        </div>
                    </Box>
                    <View>
                    <div>
                        <Typography variant="h1" style={{fontSize:'30px',color:'#000',fontFamily: "CircularStd, sans-serif", fontWeight:700, textTransform:'capitalize'}}>{this.state.category} ({inquiriesData.length})</Typography>
                        <TableContainer style={{border:'3px solid #000', background:"#fff", marginTop:"20px", padding:'10px', boxSizing:'border-box',height:775}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{...webStyle.tableCell, ...webStyle.tableHeaderSerial}}>Serial #</TableCell>
                                        <TableCell style={{...webStyle.tableCell, ...webStyle.tableHeaderUserName}}>Name</TableCell>
                                        <TableCell style={{...webStyle.tableCell, ...webStyle.tableHeaderEventName}}>Event Name</TableCell>
                                        <TableCell style={webStyle.tableCell}>Package</TableCell>
                                        <TableCell style={webStyle.tableCell}>Duration</TableCell>
                                        <TableCell style={webStyle.tableCell}>Amount</TableCell>
                                        <TableCell style={webStyle.tableCell}>Status</TableCell>
                                        <TableCell data-test-id="sort-icon"
  style={{...webStyle.tableCell, ...webStyle.tableHeaderDate}}
onClick={this.handleSortClick}
>
<span>Event Date</span>
  {this.renderSortIcon(this.state.sortDirection)}
</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { 
                                   currentRecords?.map((d:any,i:any)=>{
                                      const serialNumber = (currentPage - 1) * recordsPerPage + (i + 1);
                                      const formattedSerialNumber = serialNumber.toString().padStart(2, '0');
                                        return <TableRow data-test-id={d.id} key={i} onClick={()=>this.viewInquiry(d)}>
                                        <TableCell className="customTableCell" style={webStyle.tablebodyCell} >
                                          {formattedSerialNumber}
                                        </TableCell>
                                        <Tooltip placement="top-start" title={d.attributes.client_name}> 
                                        <TableCell className="customTableCell" style={webStyle.tablebodyCell}>{d.attributes.client_name}</TableCell>
                                        </Tooltip>
                                        <Tooltip placement="top-start" title={d.attributes.event_name}>
                                        <TableCell className="customTableCell" style={webStyle.tablebodyCell}>{d.attributes.event_name}</TableCell>
                                        </Tooltip>
                                        <Tooltip placement="top-start" title={d.attributes.service_name}>
                                        <TableCell  className="customTableCell"  style={webStyle.tablebodyCell}>{d.attributes.service_name}</TableCell>
                                        </Tooltip>
                                        <TableCell className="customTableCell"  style={webStyle.tablebodyCell}>{d.attributes.sub_category_name}</TableCell>
                                        <TableCell className="customTableCell"  style={webStyle.tablebodyCell}>£{this.renderSubtotal(d.attributes)}</TableCell>
                                        <TableCell className="customTableCell"  style={webStyle.tablebodyCell}>{d.attributes.status}</TableCell>
                
                                        <TableCell className="customTableCell"  style={webStyle.tablebodyCell}>
                                        {this.renderDate(d.attributes.event_date)}
</TableCell>


                                    </TableRow>
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                    
          <div style={{ backgroundColor: "#FFFFFF", height: "64px", boxSizing: "border-box", padding: "15px", display: "flex", justifyContent: "space-between" }}>
    <div style={{ display: "flex", alignItems: "center", gap: "14rem", marginLeft: "auto", marginRight: "auto" }}>                    <Pagination 
    data-test-id="testPage"
    count={Math.ceil(inquiriesData.length / recordsPerPage)} 
    page={this.state.currentPage} 
    defaultPage={3} siblingCount={0} boundaryCount={1}
    onChange={(event, value) => { this.handlePageChange(value) }}
    style={{ 
        display: 'flex', 
        justifyContent: 'center',
        fontFamily: 'CircularStd' 
    }} 
    hidePrevButton={this.state.currentPage === 1} 
    hideNextButton={this.state.currentPage === Math.ceil(inquiriesData.length / recordsPerPage)} 
    renderItem={(item) => (
        <PaginationItem
            {...item}
            component="div"
            style={this.getItemStyle(item.page, this.state.currentPage)}
        />
    )}
/>
</div>
    <Box style={{
        fontSize: '12px',
        fontWeight: 'normal',
        color: '#5A5A5A',
        margin: '0',
        lineHeight: '1',
    }}>
                           
                                        <p>{this.getResultsText(inquiriesData.length, indexOfFirstRecord, indexOfLastRecord)}</p>
                            </Box>
                            </div>
                    </View>
                </View>
            </Container>
          </View>
          <Dialog data-testid="dialog"
            open={this.state.openDialogName === "ViewEnquiryStatus" }
            className="custom-modal" transitionDuration={0}
            aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" scroll="body" >
            <DialogContent style={{ padding: "0px",border:"solid 3px #000",
            overflowX:"hidden",minHeight:"10%"}} data-test-id="form">
              <View style={{ paddingBottom:"30px"}} >
                <Box padding={{ 
                  xs: "15px 30px 25px",}}>
                <div data-testid="hideModal" onClick={this.onEnquiryCancel} 
                style={{position:"absolute",right:20,top:10,cursor:"pointer",}}>
              <Image
                data-testid="closeBtn"
                source={imgClose} resizeMode="cover" style={{height:18,width:18,}}
              />
            </div>
                  <Text style={{fontFamily:"CircularStdBold", 
                  color:"#000000",fontSize:30,paddingBottom:20,
                  lineHeight:62}}>
                    Event Details</Text>
                  <View style={{
              backgroundColor: getStatusColor(this.state?.EnquiryData?.attributes?.actual_status),
              borderColor: '#000', borderWidth: 3,
              padding: 5,marginBottom: 20,
              width:182,
            }}>
          {this.renderStatus()}
           </View>
           <div style={{borderBottom:"1px solid grey"}}></div>
                </Box>
                <View style={{ 
                  paddingLeft:30,
                  paddingRight:30,
                  paddingTop:30
                  }}>
                  <Grid 
                  container 
                  spacing={4} >
                 
         <Grid container spacing={3} className="inputFieldsAlignEnd" style={{ paddingLeft: 15, paddingRight: 30, maxHeight:250, overflow: "auto", marginBottom: "20px" }}>

      {this.renderEnquiryData(this.state.EnquiryData, getTooltipProps, reviewEventStartTime)}

      {this.renderGroupByAdditionalAddon()}
  <Grid item xs={6} className="inputFieldsAlignEnd reviewInputFields" 
  style={{ overflow:"auto", rowGap: '15px', maxHeight: 280, columnGap: '15px'}}>
    {this.renderNote()}
 </Grid>
</Grid>
                  <Grid item md={6} sm={8} xs={12}>
                    {this.renderViewCustomFormPopupPriceDetails()}
                  </Grid>
                  <Grid item 
                  xs={6}>

{this.renderDownload(this.state.EnquiryData.attributes)}
</Grid>
                  <View style={{ display:"flex",width:"100%",
                    paddingLeft:"1%",
                    paddingTop:30,paddingRight:20,paddingBottom:30}}>
                  <Grid container spacing={3}>
      <Box sx={eventButtonCss}>
        {this.renderCommonButtons()}
      </Box>
      {/* <Grid item style={{display:"flex",width:"100%", justifyContent:"flex-end"}}>
        {(this.state.EnquiryData?.attributes?.status === "rejected") && (
          <>
            {this.renderDisabledButtonsForRejected()}
          </>
        )}
      </Grid> */}
    </Grid>
                        </View>
                        
                  </Grid>
                  </View>
                </View>
            </DialogContent>
          </Dialog>


        <Dialog id="model_add" maxWidth="md"
          open={this.state.rejectState}
          onClose={ this.onEnquiryCancel } 
          style={{border:'3px solid black'}}>
          <DialogContent style={{ 
            alignItems:'center', maxWidth:'100%', 
            width:'480px', boxSizing:'border-box', 
            display: 'flex', border:'3px solid black', flexDirection: 'column', 
            paddingTop:'100px', height:'465px',}}> 
            <div data-testid="hideModal" onClick={()=>{this.onEnquiryCancel()}} style={{
              position:"absolute", cursor:"pointer",
              top:'16px',right:'16px'
              }}>
              <Image source={ imgClose} resizeMode="cover" style={{ 
                height:'20px', 
                width:'20px',
                }}/>
            </div>
            <Box style={{
              display: 'flex', flexDirection: 'column', 
              justifyContent: 'center', alignItems: 'center', 
              gap:'25px'}}>
                <Box style={{
                  border: 'solid 3px #000', padding: '10px', 
                  width:'fit-content', backgroundColor: '#FF5B74'}}>
                  <ClearIcon style={{ 
                    fontSize: '48px', color: 'black' , 
                    height:'60px', width:'60px'}} />
                </Box>
                <Typography variant="h5" component="h2" style={{ fontSize: 30, fontFamily:'CircularStdBold', color: "#000", textAlign:'center', marginBottom: "0", backgroundColor: 'transparent'}}>Oops!</Typography>
                <Text style={{color:"#5A5A5A", fontFamily:'CircularStd', fontSize: 14, maxWidth: 380, textAlign: 'center', marginTop: 10, lineHeight: 19,}}>The inquiry is rejected.</Text>
            </Box>
          </DialogContent>
        </Dialog> 

        <Dialog id="model_add" maxWidth="md" 
          open={this.state.openDialogName === "submit successfully ClientAdmin" } 
          onClick={this.onEnquiryCancel}  
          style={{border:'3px solid black'}}>
          <DialogContent style={{ 
            width:'480px', height:'448px', border:'3px solid black', 
            display: 'flex', flexDirection: 'column',  alignItems:'center',maxWidth:'100%', paddingTop: '65px', 
            boxSizing:"border-box"}}> 
            <div data-testid="hideModal" onClick={this.onEnquiryCancel}  style={{
              top:'16px',cursor:"pointer",
              position:"absolute",right:'16px'}}>
              <Image source={ imgClose} resizeMode="cover" style={{ height:'20px', width:'20px',}}/>
            </div>
            <Box style={{
              display: 'flex', 
              gap: '25px', flexDirection: 'column', justifyContent: 'center', 
              alignItems: 'center',}}>
                <Box style={{
                  border: 'solid 3px #000', backgroundColor: '#00BFBA', padding: '10px', 
                  width:'fit-content',}}>
                  <CheckIcon style={{ fontSize: '48px',height:'60px', width:'60px', color: 'black'}} />
                </Box>
                <Typography variant="h5" component="h2"style={{ 
                  fontSize: 30, marginBottom: "0", fontFamily:'CircularStdBold',backgroundColor: 'transparent', 
                  lineHeight: '44px', textAlign:'center', color: "#000"}}>Enquiry Approved</Typography>
                <Text style={{
                  color:"#5A5A5A", lineHeight: 28, maxWidth: 380, textAlign: 'center', 
                  fontFamily:'CircularStd', fontSize: 16, marginTop:'10px',}}>Your enquiry has been submitted to London Filmed, your Account Manager will be in touch shortly.</Text>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog id="model_add" maxWidth="md" 
              open={this.state.openDialogName === "submit successfully ClientUser" } 
              onClick={this.onEnquiryCancel}  
              style={{border:'3px solid black'}}>
              <DialogContent style={{ 
                alignItems:'center',width:'480px', minHeight:'448px', 
                border:'3px solid black',maxWidth:'100%',  display: 'flex', flexDirection: 'column', 
                paddingTop: '65px', paddingBottom:'80px', boxSizing:"border-box"}}> 
                <div data-testid="hideModal" onClick={this.onEnquiryCancel}  
                style={{ 
                  position:"absolute",cursor:"pointer", 
                  right:'16px',top:'16px',}}>
                  <Image source={ imgClose} resizeMode="cover" style={{ height:'20px', width:'20px',}}/>
                </div>
                <Box style={{
                  display: 'flex',
                  justifyContent: 'center', alignItems: 'center', 
                  flexDirection: 'column', gap: '25px'}}>
                    <Box style={{ width:'fit-content', border: 'solid 3px #000', 
                    backgroundColor: '#00BFBA',padding: '10px',}}>
                      <CheckIcon style={{ height:'60px', width:'60px', 
                      fontSize: '48px', color: 'black',}} />
                    </Box>
                    { this.renderBespokeTypography()}
                </Box>
                </DialogContent>
          </Dialog>
          <Dialog id="model_add" maxWidth="md"
          open={this.state.openDialogName==="OpenICalendar"} onClose={this.onCancel}
          style={{
            border: '3px solid black'
          }}>
          <DialogContent
            style={{
              alignItems: 'center',
              width: 400,
              height: 420,
              border: '3px solid black'
            }}>
            <div data-testid="hideModal"
              onClick={this.onCancel} style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
              <Image source={imgClose} resizeMode="cover" style={{ height: 18, width: 18, }}
              />
            </div>
            <Box style={{ border: '4px solid black', padding: '20px', backgroundColor: '#BDB9FB', height: 73, width: 73, display: "flex", flexDirection: "column", alignItems: "center", position: 'absolute', top: '10%', left: "165px" }}>
            <Image source={groupmeeticon} resizeMode="cover" style={{ height: 55, width: 55,top:5 }}
              />
              <Typography variant="h5" component="h2"
                style={{
                  fontSize: 30, color: "#000", justifyContent: "center", marginBottom: "20px",
                  display: "flex", alignItems: "center", fontFamily: 'CircularStdBold', marginTop: 55, width: 345, backgroundColor: "#fff"
                }}>
                Action Required</Typography>
                <GenericLabel color="#5A5A5A" fontFamily='CircularStd' align="center" fontSize={14} lineHeight={19} pr={15} width="350px">In order to progress your bespoke request, please schedule a meeting with us so we can discuss your requirements in a little more detail.</GenericLabel>

            </Box>
            <Box style={{ height: 83, width: 83, display: "flex", flexDirection: "column", alignItems: "center", position: 'absolute', top: '70%', left: "190px"}} >
                {this.renderCalndarType()}
    </Box>
    <div style={{ display: 'flex', marginTop: 350, marginLeft: '20%' }}>
  <View>
    {this.state.calendarType !="calendarIcon" && (
      <div className={`buttonWrapper w-150 btn-blue`} style={{ width: 250 }}>
        <div className="buttonOutline"></div>

  <Button
    data-test-id="testConfirmBtn"
    type="submit"
    className="btn-sm w-150"
    onClick={this.handleCalendarChange}
  >
   {this.renderCalendarButton()}
  </Button>

      </div>
    )}
  </View>
</div>

          </DialogContent>
        </Dialog>

        <Dialog id="model_add" maxWidth="md"
          open={ this.state.openDialogName === "NotFound" }
          onClose={ this.closeEnquiryPopup } 
          style={{border:'3px solid black'}}>
          <DialogContent style={{ 
            alignItems:'center', paddingTop:'100px', border:'3px solid black',
            width:'480px', boxSizing:'border-box', 
            display: 'flex', height:'465px',
            flexDirection: 'column',maxWidth:'100%',}}> 
            <div data-testid="hideModal" onClick={this.closeEnquiryPopup} style={{
              position:"absolute", right:'16px',
              top:'16px', cursor:"pointer",
              }}>
              <Image source={ imgClose} resizeMode="cover" style={{ 
                height:'20px', 
                width:'20px',
                }}/>
            </div>
            <Box style={{
              display: 'flex', alignItems: 'center', gap:'25px',
              justifyContent: 'center', flexDirection: 'column', 
              }}>
                <Box style={{
                  border: 'solid 3px #000', backgroundColor: '#FF5B74', padding: '10px', 
                  width:'fit-content', }}>
                  <ClearIcon style={{ 
                    fontSize: '48px', height:'60px',
                     width:'60px', color: 'black' ,}} />
                </Box>
                <Typography variant="h5" component="h2" style={{ fontSize: 30, color: "#000", marginBottom: "0", backgroundColor: 'transparent', textAlign:'center', fontFamily:'CircularStdBold',}}>Oops!</Typography>
                <Text style={{color:"#5A5A5A", textAlign: 'center', fontFamily:'CircularStd', marginTop: 10, fontSize: 14, lineHeight: 19, maxWidth: 380,}}> Something's not right. The requested enquiry cannot be found. Please check the enquiry ID and try again.</Text>
            </Box>
          </DialogContent>
        </Dialog> 

        <Dialog id="model_add" maxWidth="md"
          open={this.state.openDialogName === "already approved"} onClose={this.onCancel}
          style={{ border: '3px solid black' }}>
          <DialogContent className="pt-100" style={{
            maxWidth: '100%', width: '480px', flexDirection: 'column',
            display: 'flex', alignItems: 'center', border: '3px solid black', boxSizing: 'border-box', minHeight: '465px',
          }}>
            <div className="closeWrapper" data-testid="hideModal" onClick={this.onCancel} style={{
              position: "absolute", cursor: "pointer",
              top: '20px', right: '20px'
            }}>
              <Image source={imgClose} resizeMode="cover" style={{
                height: '20px',
                width: '20px',
              }} />
            </div>
            <Box className="contentContainer" style={{
              gap: '25px', justifyContent: 'center',
              alignItems: 'center', display: 'flex', flexDirection: 'column',
            }}>
              <Box className="contentImage" style={{
                backgroundColor: '#FF5B74', padding: '10px',
                width: 'fit-content', border: 'solid 3px #000',
              }}>
                <ClearIcon style={{
                  height: '60px', fontSize: '48px', width: '60px',
                  color: 'black'
                }} />
              </Box>
              <Typography className="heading" variant="h5" component="h2" style={{
                color: "#000", fontSize: 30, fontFamily: 'CircularStdBold',
                backgroundColor: 'transparent', textAlign: 'center', marginBottom: "0",
              }}>Oops!</Typography>
              <div className="text" style={{
                color: "#5A5A5A", textAlign: 'center', marginTop: 10, fontFamily: 'CircularStd',
                lineHeight: '19px', maxWidth: 380, fontSize: 14,
              }}>{this.state.errorMessage}</div>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.isSuspended}
          onClose={this.handleGoToLoginPage}
          data-test-id="suspendedModal"
        >
          <Typography style={accSuspendedModal.suspendedTitle}>
            {configJSON.supsendedModalTitle}
          </Typography>
          <DialogContent>
            <DialogContentText>
              {this.state.suspendedMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={accSuspendedModal.btnWrapper}>
            <Button style={accSuspendedModal.okBtn} variant="contained" disableElevation onClick={this.handleGoToLoginPage}>
              {configJSON.supspendedModalOkBtn}
            </Button>
          </DialogActions>
        </Dialog>
        </Container>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const accSuspendedModal = {
  okBtn:{
    backgroundColor:"#fff",
    textTransform:"capitalize" as "capitalize",
    color:"black",
    borderRadius:"inherit",
    border: "2px solid #000",
    boxShadow: "#584EF5 4px 4px 0 0,#000 5px 5px 0 2px",
    boxSizing: "border-box" as "border-box",
    cursor: "pointer",
    display: "inline-block",
    fontSize: "14px",
    fontWeight:600,
    paddingBlock:"3px",
  },
  suspendedTitle:{
    fontSize:20,
    color:"black",
    padding:10,
    paddingLeft:24
  },
  btnWrapper:{
    paddingRight:17,
    paddingBottom:16,
  },
}

const styles = StyleSheet.create({
    tableProvision : {
      display: "flex",justifyContent: "space-between",
      alignItems: "center",flexDirection:"row",
    },
    fontSm: {
      fontSize:12,fontFamily:"CircularStd",
    },
    fontm: {
      fontSize:16,fontFamily:"CircularStdBold",
    },
    accordionTittle :{
      fontFamily:"CircularStdBold",color:"#594FF5",
      fontSize:16,paddingBottom:0,textTransform:"capitalize",
      top:"-7px",position:"relative"
    },
    flexColumn :{
      display: "flex",justifyContent:"space-between",
      flexDirection:"row",width: "100%",
    },
    MuiMenuList:{
      display: 'flex'
    }
  })

const getStatusColor = (status:any) => {
    switch (status) {
      case 'approved':
        return '#00BFBB';
      case 'rejected':
        return '#F87171'; 
      case 'draft':
        return '#FFC500'; 
      case 'pending':
        return '#D9D9D9'; 
      default:
        return '#FFFFFF'; 
    }
  };

  const AttachmentDiv = styled(`div`)({
    boxSizing:"border-box", display: "grid",
    gridTemplateColumns: "repeat(2, 50%)", gridTemplateRows: "repeat(5, auto)",
    gap:'16px', paddingRight:'30px',
    '& .item':{
      backgroundColor: '#F0F0F0', padding: "6px",
      flexDirection:"row", display:"flex", 
      alignItems:"center", border: '3px solid #000',
      justifyContent:"space-between", paddingLeft: "10px"
    },
    '& .item:nth-child(1)': { gridRow: 1, gridColumn: 1 }, '& .item:nth-child(2)': { gridRow: 2, gridColumn: 1 },
    '& .item:nth-child(3)': { gridRow: 3, gridColumn: 1 }, '& .item:nth-child(4)': { gridRow: 4, gridColumn: 1 }, 
    '& .item:nth-child(5)': { gridRow: 5, gridColumn: 1 }, '& .item:nth-child(6)': { gridRow: 1, gridColumn: 2 },
    '& .item:nth-child(7)': { gridRow: 2, gridColumn: 2  },'& .item:nth-child(8)': { gridRow: 3, gridColumn: 2  },
    '& .item:nth-child(9)': { gridRow: 4, gridColumn: 2  },'& .item:nth-child(10)': { gridRow: 5, gridColumn: 2 },
  })

const webStyle = {
  pageCount:{
    fontSize:12,
    color:'#5A5A5A',
    fontWeight:'normal',
    lineHeight:1,
    padding:'5px',
    cursor:'pointer',
    border: 'none',
    background: 'transparent',

}as const,
tableCell:{
    fontSize:'20px',
    color:'#000',
    fontFamily: "CircularStd, sans-serif", 
    fontWeight:700,
    borderBottom:'none',
    textTransform: 'capitalize',
}as const,
tableHeaderSerial:{
  minWidth:'80px',
}as const,
tableHeaderUserName:{
  minWidth:'120px',
}as const,
tableHeaderEventName:{
  minWidth:'140px',
}as const,
tableHeaderDate: {
  display: "flex", 
  alignItems: "center", 
  cursor: "pointer", 
  minWidth: "160px" 
}as const,
tablebodyCell:{
    fontSize:'16px',
    color:'#000',
    fontFamily: "CircularStd, sans-serif", 
    fontWeight:400,
    textTransform: 'capitalize',
    cursor: "pointer",
}as const,
FilterData:{
    position: 'absolute',
    top: '0px',
    right: '0px',
    border: '3px solid #000',
    background: '#fff',
    padding: '5px',
    boxSizing: 'border-box',

}as const,
btnFilters:{
    borderRadius: 0,
    padding: '0 5px',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#5A5A5A',
    fontFamily: 'CircularStd',
    textTransform: 'capitalize',
   
}as const,
btnFilters1:{
    borderRadius: 0,
    padding: '0 5px',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#5A5A5A',
    fontFamily: 'CircularStd',
    textTransform: 'capitalize',
    borderLeft: '1px solid #000',
    borderRight: '1px solid #000',
}as const,
    hoverStyle: {
      textDecoration: 'underline',
      color: '#594FF5',
    }as const,
  contegoryTxt:{ 
    color:'#594FF5',
    fontSize:16, 
    marginBottom: 40,
    fontFamily: "CircularStdBold, sans-serif",
    textTransform:"capitalize" as "capitalize"
  },
  noFileAddedTxt:{ 
    fontFamily: "CircularStd", 
    color: "#000000", 
    fontSize: 14 
  },
  gridViewFiles: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 50%)",
    gridGap: "10px"
  },
  gridFileWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingX: "6px",
    paddingY: "10px",
    border: '3px solid #000',
  },
  filename:{
    fontFamily:"CircularStd", 
    color:"#000000",
    fontSize:16, 
    width: "80%",
    margin:0, 
    textOverflow: "ellipsis", 
    overflow: "hidden", 
    whiteSpace: "nowrap" as "nowrap"
  },
  fileDownloadBtn: {
    cursor: "pointer",
  }
}

const enqTabCss = {
  display:"flex",
  flexWrap: "wrap",
  flexDirection:"row",
  marginBottom:45,
  alignItems:"center"
};

const eventButtonCss = { 
  display: "flex", 
  justifyContent: "end", 
  flexDirection: "row" , 
  width: "100%"
};

// Customizable Area End
