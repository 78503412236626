// Customizable Area Start
import React from "react";

import {
    Button,
    Box,
    Container,
    Grid,DialogContent, Dialog,Typography,Input,
    Tooltip,
    DialogContentText,
    IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from '@material-ui/icons/Person';
import ApartmentIcon from '@material-ui/icons/Apartment';
import EmailIcon from '@material-ui/icons/Email';
import ClearIcon from '@material-ui/icons/Clear';
import { styled } from "@material-ui/styles";
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import SearchIcon from '@material-ui/icons/Search'

import TeamBuilderController, {
    Props,
    configJSON,
} from "./TeamBuilderListController.web";
import 'react-phone-input-2/lib/style.css'
import CheckIcon from '@material-ui/icons/Check';
import { imgClose } from "../../categoriessubcategories/src/assets";
import {Image,Text, View} from "react-native";
import GenericLabel from "../../../components/src/GenericLabel";
import {companyIcon ,removeIcon ,lastNameIcon,editIcon ,group_plus,
    closeBtnIcon
} from "./assets";

import Modal from "@material-ui/core/Modal";

//@ts-ignore
import warning from "../assets/warning.svg";
// @ts-ignore
import checked from "../assets/group_check.png";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";

//@ts-ignore
import imgRightArrow from '../assets/arrow.svg'
import AppHeader from "../../../components/src/AppHeader.web";
//@ts-ignore
import imgLogo from '../assets/logo.png'
//@ts-ignore
import imgNotification from '../assets/notification.png'
//@ts-ignore
import createImage from '../assets/create.png';
import PhoneInput from "react-phone-input-2";
import { loadingImg } from "../../customform/src/assets";







const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '3px solid #000',
    boxShadow: 24,
    p: 4,
};

const CustomFormWrapper = styled("div")({
    position: "relative",
    height: 46,
    display: "block",
    marginRight: 30
});

const CustomFormWrapperButton = styled("div")({
    position: "relative",
    height: 46,
    display: "flex",
    justifyContent: 'right',

});

const SubmitButton11 = styled(Button)({
    backgroundColor: "#fff",
    color: "#000",
    border: "solid 3px #000",
    borderRadius: 0,
    textTransform: "none",
    minWidth: 100,
    fontSize: "14px",
    width: "100%",
    padding: "4px",
    fontFamily: 'CircularStdBold',
    "&:hover": {
      backgroundColor:  "#FFBDC7",
    },
    "&:focus": {
      backgroundColor:  "#FFBDC7",
    }
});

const SubmitButton = styled(Button)({
    backgroundColor: "#fff",
    color: "#000",
    border: "solid 3px #000",
    borderRadius: 0,
    textTransform: "none",
    minWidth: 100,
    fontSize: "14px",
    width: "100%",
    padding: "4px",
    fontFamily: 'CircularStdBold',
    "&:hover": {
        backgroundColor: "#BDB9FB",
    },
    "&:focus": {
        backgroundColor: "#DEDCFD",
    }
});
const SubmitButtonBack = styled(Button)({
    backgroundColor: "#fff",
    color: "#000",
    border: "solid 3px #000",
    borderRadius: 0,
    textTransform: "none",
    minWidth: 100,
    fontSize: "14px",
    width: "100%",
    padding: "4px",
    fontFamily: 'CircularStdBold',
    "&:hover": {
        backgroundColor: "#99E5E4",
    },
    "&:focus": {
        backgroundColor: "#DEDCFD",
    }
});
const CreateCustomButtonOutline1 = styled("div")({
    right: '-6px',
    border: 'solid 3px #000',
    bottom: '-7px',
    height: "42px",
    zIndex: 0,
    position: 'absolute',
    backgroundColor: "#594FF5",
    width: "170px"

});
const SubmitButton1 = styled(Button)({
    backgroundColor: "#fff",
    color: "#000",
    border: "solid 3px #000",
    borderRadius: 0,
    textTransform: "none",
    minWidth: 120,
    fontSize: "14px",
    // padding: "4px",
    padding :"20px",
    fontFamily: 'CircularStdBold',
    "&:hover": {
        backgroundColor: "#BDB9FB",
    },
    "&:focus": {
        backgroundColor: "#DEDCFD",
    }
});

const CustomButtonOutline = styled("div")({
    border: "solid 3px #000",
    height: 33,
    position: "absolute",
    right: "-5px",
    bottom: '0px',
    width: "96%",
    backgroundColor: "#594FF5",
    zIndex: 0,
});

const DeleteCustomButtonOutline = styled("div")({
    border: "solid 3px #000",
    height: 33,
    position: "absolute",
    right: "-5px",
    bottom: '2px',
    width: "96%",
    backgroundColor: "#FF5B74",
    zIndex: 0,
});

const CreateCustomButtonOutline = styled("div")({
    right: '-5px',
    border: 'solid 3px #000',
    bottom: '-7px',
    height: "42px",
    zIndex: 0,
    position: 'absolute',
    backgroundColor: "#594FF5",
    width: "110px"

});

const BackCustomFormWrapper = styled("div")({
    border: "solid 3px #000",
    height: 33,
    position: "absolute",
    right: "-5px",
    bottom: '0px',
    width: "96%",
    backgroundColor: "rgb(0, 191, 186)",
    zIndex: 0,

});



export default class TeamBuilderList extends TeamBuilderController {
    constructor(props: Props) {
        super(props);
    }

    _deleteModal = () => {
        return (
            this.state.showDeleteModal && <Modal
                //@ts-ignore
                open={this.state.showDeleteModal}
                style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClose={this.handleCloseModal}
            >
                <Box
                    className="del-modal-container"
                    sx={style}
                    style={{
                        padding: 40,
                        textAlign: 'center',
                        background: '#fff',
                        width: '400px',

                    }}
                >
                    <div data-testid="hideModal"
                    onClick={this.handleCloseModal} style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
                    <Image
                      source={imgClose}
                      resizeMode="cover" style={{ height: 18, width: 18, marginRight: "-10px" }}
                    />
                  </div>

                    <img src={warning} alt="logo" width="83" height="83" style={{marginTop:"40px"}}/>
                    <Box style={{ fontSize: 30, fontWeight: 700, marginTop: 20 , fontStyle:"CircularStdBold", marginBottom :"35px"}}>Confirmation Required</Box>
                    <p style={{fontFamily :"CircularStd",fontWeight :450,color:"#5A5A5A", fontSize:"14px",lineHeight:"28px"}}>Are you sure you want to remove this team member from the database?</p>
                    <p style={{fontFamily :"CircularStd",fontWeight :450,color:"#5A5A5A",paddingLeft:10,fontSize:"14px",lineHeight:"28px",paddingTop:0, width:"95%"}}> Removing the team member will revoke their access and delete associated data.</p>
                    {/* <Grid container style={{ display: 'flex', justifyContent: 'center' , marginTop:"25px"}} spacing={1}>
                        <Grid item xs={5}>
                            <div onClick={this.handleCloseModal} style={{ marginTop: 10, cursor: "pointer", textAlign: "center" }} className="add-member-save-button deleteModalBtn"
                                data-testid={"closeDeleteMemberButton"}>
                                <Text style={{ textDecorationStyle: "solid", textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: 16 }}>Cancel</Text>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <CustomFormWrapper>
                                <DeleteCustomButtonOutline />
                                <SubmitButton11
                                    data-testid={"btnDeleteMemberButton"}
                                    type="submit"
                                    className="deleteModalBtn"
                                    onClick={() =>
                                        this.handleDeleteTeamMember(this.state.currentItem.id)
                                    }


                                >
                                    Remove
                                </SubmitButton11>
                            </CustomFormWrapper>
                        </Grid>
                    </Grid> */}
                    <div style={{ display: 'flex', marginTop: "25px", marginLeft:'20%'}}>
                          <div data-testid={"closeDeleteMemberButton"} onClick={this.handleCloseModal}  style={{marginTop:10,cursor:"pointer",textAlign:"center"}}>
              <Text style={{textDecorationStyle:"solid",textDecorationLine:"underline", color:"#000" ,fontFamily:'CircularStd',fontSize:16 }}>Cancel</Text> 
            </div>
                         
                            <View>
                            <div className="buttonWrapper w-390 btn-subcategory3" style={{marginLeft:40, width:132}}>
                              <div className="buttonOutline" style={{backgroundColor:"#FF5B74"}}></div>
                                <Button
                                   data-testid={"btnDeleteMemberButton"}
                                  type="submit"
                                  className="btn-sm w-195" 
                                  onClick={() =>
                                    this.handleDeleteTeamMember(this.state.currentItem.id)
                                }
                                >Remove</Button>
                              </div>
                            </View>
                            </div>

                </Box>
            </Modal>
        )

    }

    _createNewMember = () => {
        return this.state.createTeamMemberModal &&
        <Dialog id="model_add" maxWidth="md" open={this.state.createTeamMemberModal} onClose={this.handleCloseCreateModal} style={{ border: '3px solid black' }} className="updateDetailsPopup">
        <DialogContent style={{ alignItems: 'center', height: "auto", border: '3px solid black', width:'700px', maxWidth:'100%', boxSizing: 'border-box', padding: '110px 90px 70px' }}>
                <Box
                    style={{
                        background: '#fff',
                        textAlign: 'center',
                    }}
                >
                   <div data-testid="hideModal" onClick={this.handleCloseCreateModal} style={{ position: "absolute", right: 20, top: 20, cursor: "pointer", }}>
                  <Image
                    source={imgClose}
                    resizeMode="cover"
                    style={{ height: 18, width: 18, }}
                  />
                </div>
                    <Box style={{ fontSize: 30, fontWeight: 'bold', marginBottom:'35px', textAlign:'center' }}>User Account Creation</Box>
                    <Box style={{maxWidth: '407px', marginLeft: 'auto', marginRight: 'auto', paddingLeft:'20px',paddingRight:'20px'}}>
                    <Box sx={webStyle.label}>{configJSON.labelFirstName}*</Box>
                    <CustomInputStyle
                        data-test-id={"firstNameInput"}
                        name="firstName"
                        placeholder="First Name"
                        value={this.state.formDataValue.firstName}
                        style={{width: '100%'}}
                        onChange={this.handleChange}
                    />
                    <Box sx={webStyle.label}>{configJSON.labelLastName}*</Box>
                    <CustomInputStyle
                        id="component-outlined"
                        defaultValue="Composed TextField"
                        name="lastName"
                        data-test-id={"lastNameInput"}
                        placeholder="Last Name"
                        style={{width: '100%'}}
                        value={this.state.formDataValue.lastName}
                        onChange={this.handleChange}
                    />


                    <Box sx={webStyle.label}>{configJSON.labelCompany}*</Box>
                    <CustomInputStyle
                        id="component-outlined"
                        defaultValue="Composed TextField"
                        style={{ color: "#000", width: '100%' }}
                        disabled
                        name="company"
                        data-test-id={"companyInput"}
                        value={this.state.companyName}
                    />
                    <Box sx={webStyle.label}>{configJSON.labelEmailID}*</Box>
                    <CustomInputStyle
                        id="component-outlined"
                        defaultValue="Composed TextField"
                        name="emailId"
                        data-test-id={"emailIdInput"}
                        placeholder="Email"
                        style={{width: '100%'}}
                        value={this.state.formDataValue.emailId}
                        onChange={(e:any)=>this.handleChange(e)}
                    />
                    {this.state.isEmailError && <AlertBox>Invalid Email. Please provide a valid email.</AlertBox>}
                    <Box sx={webStyle.label}>{configJSON.labelMobileNumber}*</Box>
                    <MobileNumberWrapper data-test-id="mobileNumberWrapper">
                    <PhoneInput
  data-testid="mobileNumberInput"
  country={'gb'}
  placeholder="Phone Number"
  value={this.state.formDataValue.full_phone_number}
  onChange={(value, countryData) => this.handlePhoneChange(value, countryData)} 
  buttonStyle={{ left:"0",borderLeft: "3px solid #000", borderTop: "3px solid #000", borderBottom: "3px solid #000",textAlign:"center", borderRadius: 0,borderRight:0,backgroundColor:"#fff" }}
 inputStyle={{ border: '3px solid #000', width: '100%', height: '42px' ,fontFamily: 'CircularStd',paddingBottom:"8px",paddingTop:"8px",fontSize:"16px", borderRadius: 0, fontWeight:450, lineHeight:24, textAlign:"left"}}     
/>
</MobileNumberWrapper>
{this.state.isPhoneError && <AlertBox>Invalid Number. Please provide a valid number.</AlertBox>}

                    <Box sx={webStyle.label}>{configJSON.labelJobTitle}*</Box>
                    <CustomInputStyle
                        id="component-outlined"
                        defaultValue="Composed TextField"
                        data-test-id={"jobTitleInput"}
                        name="jobTitle"
                        placeholder="Job Title"
                        style={{width: '100%'}}
                        value={this.state.formDataValue.jobTitle}
                        onChange={this.handleChange}
                    />
                    <Box sx={webStyle.label}>{configJSON.labelCompanyType}*</Box>
                    <CustomInputStyle
                        id="component-outlined"
                        disabled
                        defaultValue="Composed TextField"
                        data-test-id={"companyTypeInput"}
                        name="companyType"
                        style={{  color: "#000000", width: '100%' }}
                        value={this.state.formDataValue.companyType}
                    />
                     <Box sx={webStyle.label}>{configJSON.labelUserType}*</Box>
                    <CustomInputStyle
                        id="component-outlined"
                        disabled
                        defaultValue="Composed TextField"
                        data-test-id={"userTypeInput"}
                        name="userType"
                        style={{  color: "#000", width: '100%' }}
                        value={"Client User"}
                        
                    />
                    </Box>
                    <div style={{ marginTop: 50, display: 'flex', justifyContent: 'end' }}>
                        <CustomFormWrapper>
                        <div className="buttonWrapper btn-green " style={{width:140}}>
                              <div className="buttonOutline" style={{backgroundColor:"#00BFBB"}}></div>
                                <Button
                                  className="btn-sm" 
                                data-testid={"btnCancelCreateModal"}
                                onClick={() =>
                                    this.handleCloseCreateModal()
                                }
                            >
                                Back
                            </Button>
                            </div>
                        </CustomFormWrapper>
                        <Tooltip title={this.validateFormFields() ? "Provide required information to add member" : ""} placement="top">
                        <CustomFormWrapper style={{marginRight:0}}>
                        <div className={`buttonWrapper w-185 btn-blue`}style={{width:140}}>
                                <div className="buttonOutline" style={{backgroundColor : this.validateFormFields() ? "#D9D9D9" : ""}}></div>
                                
                                <Button
                                className="btn-sm w-185" 
                                data-testid={"btnAddMemberApi"}
                                type="submit"
                                disabled={this.validateFormFields()}
                                onClick={(e) => this.handleSubmit(this.state.formDataValue.emailId)
                                }
                                style={{backgroundColor : this.validateFormFields() ? "#D9D9D9" : ""}}

                            >Add Member
                            </Button>
                            </div>
                        </CustomFormWrapper></Tooltip>
                    </div>

                </Box> 
           </DialogContent>
        </Dialog>

    }



    _updateMemberDetails = () => {
        return this.state.showUpdateModal &&
            <Dialog datat-testid="updateMemberDetailsModal" open={this.state.showUpdateModal} onClose={this.handleCloseCreateModal} style={{ 
                border: '3px solid black'}}
                className="updateDetailsPopup"
                >
                <DialogContent style={{ alignItems: 'center', height: "100%",
                border: '3px solid black',
                background: '#fff', padding: '110px 90px 70px', width:'700px', maxWidth:'100%', boxSizing: 'border-box'
                }}>
                    <Box className="del-modal-container">
                        <div style={{ justifyContent: 'right', display: 'flex', width: '100%',cursor: 'pointer' , marginLeft:"20px"}} onClick={this.handleCloseCreateModal}>
                             <Image
                                source={imgClose}
                                resizeMode="cover"
                                style={{ height: 18, width: 18, right:'20px', top:'20px', position: 'absolute' }}
                            /> 
                        </div>
                        <Box style={{ fontSize: 25, fontWeight: 'bold', textAlign:'center', marginBottom:'35px' }}>Update User Details</Box>
                        <Box style={{paddingLeft:'20px', paddingRight:'20px', maxWidth:'407px', marginLeft:'auto', marginRight:'auto'}}>
                        <Box sx={{ ...webStyle.label1 }}>{configJSON.labelFirstName}{configJSON.labelStar}</Box>
                        <CustomInputStyle
                            data-test-id={"firstNameInput"}
                            id="component-outlined"
                            defaultValue="Composed TextField"
                            name="firstName"
                            style={{width:'100%'}}
                            value={this.state.formDataValue.firstName}
                            onChange={this.handleChange}
                        />
                        <Box sx={{ ...webStyle.label1, }}>{configJSON.labelLastName}{configJSON.labelStar}</Box>
                        <CustomInputStyle
                            name="lastName"
                            id="component-outlined"
                            defaultValue="Composed TextField"
                            data-test-id={"lastNameInput"}
                            style={{width: '100%'}}
                            value={this.state.formDataValue.lastName}
                            onChange={this.handleChange}
                        />
                        <Box sx={{ ...webStyle.label1, }}>{configJSON.labelCompany}{configJSON.labelStar}</Box>
                        <CustomInputStyle
                            name="company"
                            id="component-outlined"
                            defaultValue="Composed TextField"
                            data-test-id={"companyInput"}
                            style={{width: '100%'}}
                            value={this.state.formDataValue.company}
                            disabled
                        />
                         <Box sx={{ ...webStyle.label1, }}>{configJSON.labelEmailID}{configJSON.labelStar}</Box>
                        <CustomInputStyle
                            name="emailId"
                            id="component-outlined"
                            defaultValue="Composed TextField"
                            data-test-id={"emailIdInput"}
                            style={{width: '100%'}}
                            value={this.state.formDataValue.emailId}
                            onChange={this.handleChange}
                        />

                        <Box sx={{ ...webStyle.label1, }}>{configJSON.labelMobileNumber}{configJSON.labelStar}</Box>
                        <MobileNumberWrapper data-test-id="mobileNumberWrapper">
                        <PhoneInput
                            data-test-id={"phoneNumberInput"}
                            country={'us'}
                            placeholder="Phone Number"
                            value={this.state.formDataValue.full_phone_number}
                            onChange={(value, countryData) => this.handlePhoneChange(value, countryData)}
                            buttonStyle={{ 
                                borderTop: "3px solid #000", borderBottom: "3px solid #000", textAlign:"center", borderRadius: 0,borderRight:0,backgroundColor:"#fff", fontFamily: "CircularStd" ,marginLeft:"3px"}}
                            inputStyle={{ border: '3px solid #000', width: '100%', height: '40px', fontFamily: 'CircularStd', paddingBottom: "8px", paddingTop: "8px", fontSize: "16px", borderRadius: 0, }}

                        />
                        </MobileNumberWrapper>
                        {this.state.isPhoneError && <UpdateAlertBox>Invalid Number. Please provide a valid number.</UpdateAlertBox>}

                        <Box sx={{ ...webStyle.label1, }}>{configJSON.labelJobTitle}{configJSON.labelStar}</Box>
                        <CustomInputStyle
                            data-test-id={"jobTitleInput"}
                            name="jobTitle"
                            id="component-outlined"
                            defaultValue="Composed TextField"
                            style={{width: '100%'}}
                            value={this.state.formDataValue.jobTitle}
                            onChange={this.handleChange}
                        />
                           <Box data-test-id="jobTitleLabel" sx={webStyle.label1}>{configJSON.labelCompanyType}*</Box>
                    <CustomInputStyle
                    name="companyType"
                       id="component-outlined"
                       defaultValue="Composed TextField"
                       style={{width: '100%'}}
                       disabled
                        value={this.state.formDataValue.companyType}
                    />
                        <Box sx={{ ...webStyle.label1, }}>{configJSON.labelUserType}{configJSON.labelStar}</Box>
                        <CustomInputStyle
                            data-test-id={"userTypeInput"}
                            name="userType"
                            id="component-outlined"
                            defaultValue="Composed TextField"
                            style={{width: '100%'}}
                            disabled
                            value={this.state.formDataValue.userType === "ClientUser" ? "Client User":"Client Admin"}
                        />
                        </Box>

                        <div style={webStyle.caModalsEditBottons}>
                        <CustomFormWrapper>
                        <div className="buttonWrapper btn-green " style={{width:140}}>
                              <div className="buttonOutline" style={{backgroundColor:"#00BFBB",bottom:"0px",width:"96%", right:"-6px"}}></div>
                                <Button
                                  className="btn-sm" 
                                data-testid={"btnCancelCreateModal"}
                                onClick={
                                    this.handleCloseCreateModal
                                }
                            >
                                Back
                            </Button>
                            </div>
                        </CustomFormWrapper>

                        <CustomFormWrapper style={{marginRight:0}}>
                        <div className={`buttonWrapper w-185 btn-blue`}>
                                <div className="buttonOutline"style={{bottom:"0px",width:"96%"}}></div>
                                <Button
                                className="btn-sm"
                                data-testid={"btnAddMemberApi"}
                                style={{backgroundColor:this.validateFormFieldsForEditClientUser() ? "#d9d9d9" : "",width:150}}
                                type="submit"
                                onClick={this.updateMemberAPICall}
                                data-test-id="updateApi"
                                disabled={this.validateFormFieldsForEditClientUser()}
                            >{configJSON.labelUpdate}
                            </Button>
                            </div>
                        </CustomFormWrapper>
                    </div>

                    </Box>
                </DialogContent>
            </Dialog>

    }


    _createNewVerification = () => {
        return this.state.showVerificationModal &&
            <Modal
                id="modal"
                //@ts-ignore
                open={this.state.showVerificationModal}
                style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100% ', padding: 40 }}
            >
                <Box
                    className="del-modal-container"
                    sx={style}
                    style={{
                        background: '#fff',
                        padding: 40,
                        width: '400px',
                        textAlign: 'center',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>

                        <CloseIcon data-testId={"closeVerificationButton"} onClick={() =>
                            this.handleCloseVerificationModal()
                        } style={{ cursor: 'pointer' }} />
                    </div>
                    <img src={checked} alt="logo" width="120" height="120" />
                    <h2>Verification Sent</h2>
                    <p>A verification email has been sent to your team member's email address.</p>
                </Box>
            </Modal>

    }

    renderNoSearchResultsFound = () => {
        return this.state.Teams.length === 0 && this.state.searchMember !== "" && 
        <div data-testid="noMatchFound" style={webStyle.searchWarn}>{configJSON.noMatchFound}</div>
    }

    renderSuspendedModal = () => {
        return (
            <Dialog
                open={this.state.isSuspended}
                onClose={this.handleGoToLoginPage}
                aria-labelledby="suspended-account-dialoge"
                aria-describedby="suspended-account-dialoge-description"
                data-test-id="suspendedModal">
                <Box style={accountSuspendedModal.container} >
                    <IconButton
                        disableTouchRipple
                        disableRipple
                        disableFocusRipple
                        data-test-id="suspendedModalCloseBtn"
                        onClick={this.handleGoToLoginPage}
                        style={accountSuspendedModal.crossMarkCloseBtn}>
                        <img src={closeBtnIcon} />
                    </IconButton>
                    <Box style={accountSuspendedModal.modalContentWrapper}>
                        <Box style={accountSuspendedModal.crossMark}>
                            <ClearIcon style={accountSuspendedModal.crossMarkIcon} />
                        </Box>
                        <Typography style={accountSuspendedModal.suspendedTitle}>
                            {this.state.suspendedTitle}
                        </Typography>
                        <DialogContent>
                            <DialogContentText
                                style={accountSuspendedModal.suspendedMessage}
                                id="suspended-account-dialoge-description"
                                data-test-id="suspendedMessageID">
                                {this.state.suspendedMessage}
                            </DialogContentText>
                        </DialogContent>
                    </Box>
                </Box>
            </Dialog>
        )
    }

    renderLoadingComponent = () => {
        if(!this.state.isLoading) return 
        return  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',width:"100%", backgroundColor: 'transparent' }}>
             <img src={loadingImg}  style={{height:"50%",width:"25%"}}/>
             {this.renderSuspendedModal()}
        </div>
    }

    renderClearButton = () => {
        if(this.state.searchMember.length <= 0) return
        return <div data-testid="clearSearch" style={{display:"flex", fontSize:"14px", width:"fit-content", position:"absolute", right:"10px", top:0, bottom:0, alignItems:"center", cursor:"pointer"}} onClick={()=>{this.handleSearch(""); this.handleSearchValue("")}}>Clear</div>
    }

    renderBorder = (index: any) => {
        return index === this.state.currentTeams.length - 1 ? 'none' : '1px solid #ccc'
    }

    renderSXProps = (item : any) => {
        return item.attributes?.activated ? webStyle.pendingColor : webStyle.desc
    }

    renderResendEmailLink = (item : any) => {
        if(item.attributes?.activated) return '';
        return <Box style={{ cursor: 'pointer' }} onClick={() => this.verificationEmailApi(item.id)} sx={webStyle.resendVerification}>Resend Verification Email</Box>
    }

    renderIcons = (item: any, index: any) => {
        if(item.attributes?.type !== "ClientAdmin"){
            return <>
                    <Box style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} sx={webStyle.title}>
                        <img src={editIcon} data-test-id={`updateButton-${index}`} onClick={() => this.handleUpdateModal(item)} />
                    </Box>
                    <Box style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} data-testid={`deleteMemberId-${index}`} onClick={() => this.handleOpen(item)} sx={webStyle.title}>
                        <img src={removeIcon} />
                    </Box>
                </>
        }else {
            return <>
                    <Box style={{ display: 'flex', justifyContent: 'center', opacity: 0.5 }} sx={webStyle.title}>
                        <img src={editIcon} />
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'center', opacity: 0.5 }} sx={webStyle.title}>
                        <img src={removeIcon} />
                    </Box>
                </>
        }
    }

    renderTeams = () => {
        const currentTeams = this.state.currentTeams
        if(currentTeams.length <= 0) return
        return currentTeams.map((item: any, index: number) => {
                                const user = item.attributes?.type
                                const userType = user.indexOf('t')
                                const modifiedText = user.substring(0, userType + 1) + " " + user.substring(userType + 1);
                                return (
                                    <Box key={item.attributes.id} 
                                    sx={{ ...webStyle.detailContainer, borderBottom: this.renderBorder(index) }}
                                    data-user-id={item.id} >
                                        <Box sx={webStyle.row}>
                                            <Grid container spacing={4}>
                                                <Grid item md={3} sm={6} xs={10}>
                                                    <Box style={webStyle.dataColumn}>
                                                        <Box sx={webStyle.title}>
                                                            <PersonIcon style={{ marginRight: 10, color: '594FF5' }} />
                                                            {configJSON.labelCompanyType}
                                                            :&nbsp;
                                                            <Box sx={webStyle.desc}>{item.attributes?.account_type}</Box>
                                                        </Box>
                                                        <Box sx={webStyle.title}><PersonIcon style={{ marginRight: 10, color: '594FF5' }} /> {configJSON.labelFirstName}:&nbsp; <Box sx={webStyle.desc}>{item.attributes?.first_name}</Box></Box>
                                                        <Box sx={webStyle.title}> 
                                                            <img src={lastNameIcon} style={{marginRight: 10}}/>

                                                        {configJSON.labelLastName}:&nbsp; <Box sx={webStyle.desc}>{item.attributes?.last_name}</Box></Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={4} sm={6} xs={10}>
                                                    <Box style={webStyle.dataColumn}>
                                                        <Box sx={webStyle.title}>
                                                            <img src={companyIcon} style={{marginRight:10}} />
                                                            {configJSON.labelCompany}:&nbsp; <Box sx={webStyle.desc}>{item.attributes?.company?.name}</Box></Box>
                                                        <Box sx={webStyle.title}><EmailIcon style={{ marginRight: 10, color: '594FF5' }} />{configJSON.labelEmail}:&nbsp; <Box sx={webStyle.desc}>{item.attributes?.email}</Box></Box>
                                                        <Box sx={webStyle.title}><PhoneAndroidIcon style={{ marginRight: 10, color: '594FF5' }} />{configJSON.labelMobileNumber}:&nbsp; <Box sx={webStyle.desc}>{item.attributes?.full_phone_number}</Box></Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={4} sm={6} xs={10}>
                                                    <Box style={webStyle.dataColumn}>
                                                        <Box sx={webStyle.title}><PersonIcon style={{ marginRight: 10, color: '594FF5' }} />{configJSON.labelJobTitle}:&nbsp; <Box sx={webStyle.desc}>{item.attributes?.job_title}</Box></Box>
                                                        <Box sx={webStyle.title}><PersonIcon style={{ marginRight: 10, color: '594FF5' }} />{configJSON.labelUserType}:&nbsp; <Box sx={webStyle.desc}>{modifiedText}</Box></Box>
                                                        <Box sx={webStyle.title}><DoneIcon style={{ marginRight: 10, color: '594FF5' }} />
                                                            {configJSON.labelActivationStatus}:&nbsp;
                                                            <Box style={{ cursor: 'text' }} sx={this.renderSXProps(item)} data-testid="activationStatus">
                                                               {this.handleActiovationStatusText(item.attributes.activated)}
                                                            </Box>
                                                        </Box>
                                                        {this.renderResendEmailLink(item)}


                                                    </Box>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Box style={webStyle.dataColumn}>
                                                    {this.renderIcons(item, index)}

                                                    </Box>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </Box>
                                )
                            })
    }

    getStyle = (item : any) => ({
            textDecoration: item.page !== this.state.currentPage ? 'underline' : 'none',
            color: item.page === this.state.currentPage ? '#594FF5' : 'inherit',
            backgroundColor: item.page === this.state.currentPage ? 'transparent' : 'inherit'
    })

    render() {
        // Merge Engine - render - Start
        const { Teams, currentPage, itemsPerPage } = this.state;
        this.renderLoadingComponent()
        const indexOfLastRecord = currentPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;

        return (
            <Box style={{ backgroundColor: 'rgba(248, 244, 255, 0)' }}>
                <AppHeader
                    logoUrl={imgLogo}
                    homeText="Home"
                    createImageUrl={createImage}
                    userUrl={createImage}
                    settingsIcon
                    helpCenterNotificationUrl={imgNotification}
                    onClickViewProfile={() => this.goToProfileTeam()}
                    onPressHome={() => this.goToHome()}
                    onClickHelpCenter={() => this.goToHelpCenterTeam()}
                    helpCenterText="Help Centre"
                    onClickViewEnquiries={() => this.goToEnquiryTeam()}
                    quoteUrl={group_plus}
                    onClickSettings={() => this.goToSettingsTeam()}
                    onClickQuote={() => this.goToQuote()}
                    data-test-id="testAppHeader"
                />
       
                <Container maxWidth="xl" style={webStyle.container}>

                    <Box style={{ flexDirection: 'row', display: 'flex', alignItems: 'baseline' }}>
                        <Box onClick={this.goToHome} style={{ color: '#5A5A5A', cursor: 'pointer', marginBottom: 30, fontFamily: "CircularStd, sans-serif", }} data-testid="gotoHome">
                            Home
                        </Box>
                        <Box>

                            <img src={imgRightArrow} width={10} style={{ marginLeft: 10, marginRight: 10 }} alt="imagRightArrow" />
                        </Box>
                        <Box style={{ color: '#594FF5', marginBottom: 30, fontFamily: "CircularStd, sans-serif", fontWeight: 'bold' }}>
                            Team Members
                        </Box>

                    </Box>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent:'space-between',
                        alignItems: 'center',
                        paddingBottom:10
                    }}>
                    <Box style={{ fontSize: 25, fontWeight: 'bold' }}>Team Members</Box>
                        <Wrapper>
                            <Icon />
                            <SearchInput data-testid="searchInput" type="text" value={this.state.searchMember} onChange={(e)=>{this.handleSearch(e.target.value); this.handleSearchValue(e.target.value)}} placeholder="Search" style={{fontFamily:"CircularStd"}}/>
                            {this.renderClearButton()}
                            {this.renderNoSearchResultsFound()}
                        </Wrapper>
                    </div>

                    <Box style={{...webStyle.card, height: '650px'}} data-testid="usersListContainer">
                        {this.renderTeams()}


                        {this._deleteModal()}
                        {this._createNewMember()}
                        {this._createNewVerification()}
                        {this._updateMemberDetails()}


                    </Box>


                    <Box style={{ marginTop: 20 }} >
                        <CustomFormWrapperButton>
                            <CreateCustomButtonOutline1 />
                            <SubmitButton1
                                onClick={() => {this.getProfileAccountApi();this.handleOpenCreateModal()}}
                                data-testid={"createAddMember"}
                                type="submit"
                            >
                                {configJSON.btnAddTeam}
                            </SubmitButton1>
                        </CustomFormWrapperButton>
                    </Box>
                    <Box style={{ background: "#fff", marginTop: "15px", padding: '25px', textAlign: 'center', fontFamily: "CircularStd, sans-serif" }}>
                        <Box style={{ display: 'inline-block' }}>
                        <Pagination 
    data-test-id="testPage"
    count={Math.ceil(Teams.length / itemsPerPage)} 
    page={this.state.currentPage} 
    defaultPage={1} 
    siblingCount={0}
    boundaryCount={2}
    onChange={(event, value) => { this.handlePageChange(value) }}
    style={{ display: 'flex', justifyContent: 'center',fontFamily: 'CircularStd' }} 
    hidePrevButton={this.state.currentPage === 1} 
    hideNextButton={this.state.currentPage === Math.ceil(Teams.length / itemsPerPage)} 
    renderItem={(item) => (
        <PaginationItem
            data-test-id="testPage2"
            {...item}
            component="div"
            style={this.getStyle(item)}
        />
    )}
/>

                        </Box>
                        <Box style={{
                            float: 'right',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            color: '#5A5A5A',
                            margin: '0',
                            lineHeight: '1',
                        }}><p>{` ${indexOfFirstRecord + 1} - ${Math.min(indexOfLastRecord, Teams.length)} of ${Teams.length} results`}</p>
                 </Box>
                 </Box>
                    


                </Container>

                {/* success verfication set modal*/}

                <Dialog id="model_add" maxWidth="md"
                    open={this.state.successModal} onClose={this.hideModal}
                    style={{
                        border: '3px solid black'
                    }}>
                    <DialogContent
                        style={{
                            alignItems: 'center',
                            width: 400,
                            height: 375,
                            border: '3px solid black'
                        }}>
                        <div data-testid="hideModal" onClick={this.hideModal} style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
                            <Image 
                            source={imgClose} 
                            resizeMode="cover"
                            style={{ height: 18, width: 18, }} 
                            />
                        </div>
                        <Box style={{
                            border: '4px solid black',
                            padding: '20px',
                            backgroundColor: 'var(--Status-Green-600, #00BFBA)',
                            height: 83,
                            width: 83,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: 'absolute',
                            top: '19%',
                            left: "165px"
                        }}>
                            <CheckIcon style={{
                                fontSize: '48px',
                                color: 'black',
                                height: '80px',
                                width: '80px'
                            }} />
                            <Typography 
                            variant="h5" 
                            component="h2" 
                            style={{
                                fontSize: 30,
                                color: "#000",
                                justifyContent: "center",
                                marginBottom: "30px",
                                display: "flex",
                                alignItems: "center",
                                fontFamily: 'CircularStdBold',
                                marginTop: 55, width: 345,
                                backgroundColor: "#fff"
                            }}
                            >
                                Verification Sent
                            </Typography>
                            <GenericLabel color="#5A5A5A" fontFamily='CircularStd' align="center" fontSize={14} lineHeight={19} pr={0} width='380px' mt={10}>A verification email has been sent to your team member's email address.</GenericLabel>
                        </Box>

                    </DialogContent>
                </Dialog> 
                <Dialog id="model_add" maxWidth="md"
                open={this.state.openModel==="Warning"}
                 onClose={this.handleCloseCreateModal} 
                 style={{
                  border:'3px solid black'
                  }}>
            <DialogContent 
            style={{ 
              alignItems:'center', 
              width:400,
               height:420, 
               border:'3px solid black'}}> 
            <div data-testid="hideModal" 
            onClick={this.handleCloseCreateModal} style={{position:"absolute",right:20,top:10,cursor:"pointer",}}>
              <Image source={imgClose}  resizeMode="cover"
              style={{
                height:18,
                width:18,
            }}
              />
            </div>
            <Box style={{border: '4px solid black', 
            padding: '20px', backgroundColor: 'var(--Status-Amber-400, #FFBA00)' , height:83,width:83,display: "flex",flexDirection: "column",alignItems: "center",position:'absolute', top:'10%',left:"165px"}}>
  <Typography variant="h5" component="h2" style={{fontSize: 88,color: "#000",justifyContent: "center", 
  backgroundColor:"var(--Status-Amber-400, #FFBA00)",display: "flex", alignItems: "center", fontFamily:'CircularStdBold',marginTop:-15}}>?</Typography>
<Typography variant="h5" component="h2" style={{ fontSize: 30,color: "#000",justifyContent: "center", 
marginBottom: "20px",display: "flex", alignItems: "center", fontFamily:'CircularStdBold',marginTop:35, width:345,backgroundColor:"#fff"}}>
   Are you sure?</Typography>
 <GenericLabel color="#5A5A5A" fontFamily='CircularStd' align="center" 
  fontSize={14} lineHeight={19} pr={15} width="350px">{this.state.warningMessage}</GenericLabel>
</Box>
<div style={{ display: 'flex',
 marginTop: 350, marginLeft:'10%'}}>
                          
<View>
<div style={{width:150}}
className={`buttonWrapper w-220 btn-subcategory3` }>
                        <div className={`buttonOutline subcategoryBg3`}></div>
                             
                                <Button
                                  data-test-id="confirmBtn" type="submit" className="btn-sm w-150" onClick={this.handleCloseCreateModal}
                                >Cancel</Button>
                              </div>
                            </View>
                         
                            <View>
                            <div className={`buttonWrapper w-150 btn-blue`} style={{marginLeft:20,width:150}}>
                                      <div className="buttonOutline"></div>
                                <Button
                                  data-test-id="confirmBtn1"
                                  type="submit"
                                  className="btn-sm w-150" 
                                  onClick={this.popMemberAPICall}
                                >Proceed</Button>
                              </div>
                            </View>
                            </div>
            
              </DialogContent>
            </Dialog>

            <Dialog id="model_add" maxWidth="md"
                open={this.state.openModel==="Confirmation"}
                onClose={this.handleCloseCreateModal} 
                style={{
                border:'3px solid black'
                }}>
                <DialogContent 
                    style={{ 
                    alignItems:'center', 
                    width:400,
                    height:"auto", 
                    border:'3px solid black'}}> 
                    <Box style={{
                        border: '4px solid black', padding: '20px', 
                        backgroundColor: 'var(--Status-Amber-400, #FFBA00)' , 
                        height:83,width:83,display: "flex",flexDirection: "column",alignItems: "center",position:'absolute', top:'10%',left:"165px"}}>
                        <Typography
                        variant="h5"
                        component="h2"
                        style={{
                            fontSize: 88,color: "#000",
                            justifyContent: "center", backgroundColor:"var(--Status-Amber-400, #FFBA00)",
                            display: "flex", alignItems: "center", 
                            fontFamily:'CircularStdBold',
                            marginTop:-15
                        }}
                        >?</Typography>

                        <Typography
                        variant="h5"
                        component="h2"
                        style={{
                            fontSize: 30,color: "#000",
                            justifyContent: "center", marginBottom: "20px",
                            display: "flex", alignItems: "center", 
                            fontFamily:'CircularStdBold',
                            marginTop:35, width:345,backgroundColor:"#fff"
                        }}
                        >
                        Confirmation Required
                        </Typography>
                        <GenericLabel color="#5A5A5A" fontFamily='CircularStd' align="center"  fontSize={14} lineHeight={28} pr={15} width="350px">{this.state.warningMessage}</GenericLabel>
                    </Box>
                    <div style={{ display: 'flex', marginTop: 350, marginLeft:'30%', paddingTop:40, paddingBottom:30}}>
                          <div data-testid="hideModalText" onClick={this.handleCloseCreateModal}
                          style={{marginTop:10,cursor:"pointer",textAlign:"center"}}>
                          <CustomConfirmationText>Cancel</CustomConfirmationText> 
                          </div>
                         
                            <View>
                            <div className="buttonWrapper btn-green w-390" style={{marginLeft:20, width:120}}>
                              <div className="buttonOutline" style={{backgroundColor:"#00BFBB"}}></div>
                                <Button
                                  data-test-id="confirmBtn"
                                  type="submit"
                                  className="btn-sm w-195" 
                                  onClick={this.popMemberAPICall}
                                >Confirm</Button>
                              </div>
                            </View>
                            </div>
                </DialogContent>
            </Dialog>

            <Dialog id="model_add" maxWidth="md"
              open={this.state.openModel==="Error"} onClose={this.handleCloseCreateModal}  
              style={{border:'3px solid black'}}>
              <ResponsiveDialogContent className="pt-100" style={{ 
                alignItems:'center', boxSizing:'border-box',
                maxWidth:'100%', width:'480px',  
                display: 'flex', flexDirection: 'column', minHeight:'465px', border:'3px solid black',}}> 
                <div className="closeWrapper" data-testid="hideModal" onClick={this.handleCloseCreateModal} style={{
                  position:"absolute", cursor:"pointer", 
                  top:'20px',right:'20px'
                  }}>
                  <Image source={ imgClose} resizeMode="cover" style={{ 
                    height:'20px', width:'20px',
                    }}/>
                </div>
                <Box className="contentContainer" style={{
                  display: 'flex', justifyContent: 'center',
                  alignItems: 'center', flexDirection: 'column', gap:'25px'}}>
                    <Box className="contentImage" style={{
                      backgroundColor: '#FF5B74', border: 'solid 3px #000',
                      width:'fit-content', padding: '10px',}}>
                      <ClearIcon style={{ 
                        fontSize: '48px', width:'60px',
                        height:'60px', color: 'black' ,}} />
                    </Box>
                    <Typography className="heading" variant="h5" component="h2" style={{ fontSize: 30, marginBottom: "0", backgroundColor: 'transparent', fontFamily:'CircularStdBold', color: "#000", textAlign:'center', }}>Oops!</Typography>
                    <div className="text" style={{
                      color:"#5A5A5A", textAlign: 'center', fontSize: 14, 
                      lineHeight: '19px', maxWidth: 380, fontFamily:'CircularStd', 
                      marginTop: 10,}}>{this.state.warningMessage}</div>
                </Box>
              </ResponsiveDialogContent>
            </Dialog>

            <Dialog id="model_add" maxWidth="md"
                 open={this.state.showUpdateSuccessMsg} onClose={this.handleCloseCreateModal} 
                 style={{
                  border:'3px solid black'
                  }}>
            <DialogContent 
            style={{ 
              alignItems:'center', 
              width:400,
               height:375, 
               border:'3px solid black'}}> 
            <div data-testid="hideModal" onClick={this.handleCloseCreateModal} style={{position:"absolute",right:20,top:10,cursor:"pointer",}}>
              <Image source={imgClose} resizeMode="cover" style={{height:18,width:18,}}/>
            </div>
            <Box style={{
               border: '4px solid black', padding: '20px', backgroundColor: 'var(--Status-Green-600, #00BFBA)' , 
               height:83,width:83,display: "flex",
flexDirection: "column",alignItems: "center",
position:'absolute', top:'30%',left:"165px"}}>
<CheckIcon style={{ fontSize: '48px', 
color: 'black' , height:'80px',width:'80px'}} />
<Typography variant="h5"component="h2"style={{
	 fontSize: 30,color: "#000",
	 justifyContent: "center", marginBottom: "30px",
	 display: "flex", alignItems: "center", 
	 fontFamily:'CircularStdBold',
	 marginTop:40, width:345,
   backgroundColor:"#fff"
   }}
 >
   Successfully Updated
 </Typography>
</Box>
            
              </DialogContent>
            </Dialog> 

                {this.renderSuspendedModal()}
            </Box>
        );
        // Merge Engine - render - End
    }
}


const accountSuspendedModal = {
    container: {
        maxWidth: '100%',
        width: '480px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        border: '3px solid black',
        boxSizing: 'border-box' as "border-box",
        minHeight: '465px',
    },
    modalContentWrapper: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
    },
    crossMark: {
        backgroundColor: '#FF5B74',
        border: 'solid 3px #000',
        width: 'fit-content',
        padding: '12px',
        marginBottom: 12
    },
    crossMarkIcon: {
        height: '55px',
        color: 'black',
        width: '55px',
    },
    crossMarkCloseBtn: {
        position: "absolute" as "absolute",
        top: '3px',
        right: '3px'
    },
    crossMarkCloseIcon: {
        fontWeight: 900,
        color: "black",
        fontSize: "25x",
        width: "25px",
        height: "25px"
    },
    suspendedTitle: {
        fontSize: "clamp(1.5rem, 1.3917rem + 0.5333vw, 1.875rem)",
        color: "black",
        padding: 10,
        fontWeight: 700,
        fontFamily: 'CircularStd',
        marginBottom: 14
    },
    suspendedMessage: {
        textAlign: "center" as "center",
        fontSize: "14px",
        fontFamily: 'CircularStd',
        color: "#5A5A5A",
        paddingInline: "20px"
    },
}

const webStyle = {
    container: {
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        marginTop: 30,
        width: '95%',
       
    },
    pageCount1: {
        fontSize: 12,
        color: '#5A5A5A',
        fontWeight: 'normal',
        lineHeight: 1,
        padding: '5px',
        cursor: 'pointer',
        border: 'none',
        background: 'transparent',

    },
    dataColumn: {
        padding: '30px 20px 30px 20px'
    },
    btnContainer: {
        marginVertical: 20,
        backgroundColor: "#4da6ff",
        alignItems: 'center',
        width: '80%',
        paddingVertical: 10,
        justifyContent: 'center',
        marginBottom: 50,
        borderRadius: 5,
        alignSelf: 'center'
    },
    btnText: {
        fontWeight: 'bold',
        fontSize: 18,
        color: "white",
    },
    bgRectBorder: {
        borderWidth: 1,
        borderColor: "#767676",
        borderRadius: 2,
        marginBottom: 10
    },
    pendingColor: {
        color: '#00BFBA',
        fontWeight: 100
    },
    resendVerification: {
        color: '#3f51b5',
        fontSize: 13,
        marginLeft: '35px'
    },

    bgRectBorder1: {
        border: '2px solid #767676',
        height: '40px',
        width: '90%'
    },

    row: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
    },
    card: {
        backgroundColor: 'white',
        border: '3px solid #000000',
        alignSelf: 'center',
        marginTop: 20,
        overflow: 'auto',
        padding : "50 25 150 25"
    },
    edit: {
        fontWeight: '600',
        color: 'green',
        width: "50%",
        fontSize: 18,
        textAlign: 'center',
        borderRightColor: '#cccccc',
        borderTopColor: '#cccccc',
        borderBottomColor: 'white',
        borderWidth: 1,
        borderLeftColor: 'white',
        paddingVertical: 8
    },
    delete: {
        fontWeight: '600',
        fontSize: 18,
        color: 'red',
        width: "50%",
        borderTopColor: '#cccccc',
        borderRightColor: 'white',
        textAlign: 'center',
        borderWidth: 1,
        borderBottomColor: 'white',
        borderLeftColor: 'white',
        paddingVertical: 8
    },
    deleteModalBtn: {
        minWidth: 200
    },
    detailContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingVertical: 20,
        padding: '10 10 10 10',
        alignItems: 'center'
    },

    profile: {
        height: 60,
        width: 60,
        marginRight: 15,
        borderRadius: 50,
    },
    title: {
        fontSize: 15,
        fontWeight: "bold",
        marginBottom: 6,
        color: 'black',
        marginEnd: 5,
        display: "flex",
        alignItems: 'center',
        minHeight: "24px"
    },
    desc: {
        color: '#000000',
        fontSize: 15,
        fontWeight: '200',
        marginRight: 10
    },
    flatStyle: {
        width: '100%'
    },
    noFound: {
        fontSize: 24,
        alignSelf: 'center',
        fontWeight: '600',
        marginVertical: 40
    },
    label: {
        color: "#000",
        fontSize: 16,
        fontWeight: '450',
        marginTop: 30,
        fontFamily:"CircularStd",
        marginBottom: 8,
        display: 'flex',
        lineHeight:"24px",        
        textAlign:"left"
    },
    label1: {
        color: "#000",
        fontSize: 16,
        fontWeight: '600',
        marginTop: 30,
        fontFamily:"CircularStd",
        marginBottom: 8,
        display: 'flex'
    },
    multiDropDown:{
        backgroundColor: "#d9d9d9", 
        width: "100%", 
        textAlign: "left" as "left",
        height: "41px", 
        fontSize: "14px", 
        fontFamily: "CircularStd"
    },
    caModalsEditBottons:{ 
        marginTop: 50, 
        display: 'flex', 
        justifyContent: 'end',
        maxWidth: "407px",
        paddingInline: "20px",
        marginInline: "auto" 
    },
    searchWarn:{
        height:'52px', 
        width:"100%", 
        backgroundColor:"white", 
        display:"flex",
        position:"relative" as "relative",
        borderRight:"3px solid black",
        borderBottom:"3px solid black",
        borderLeft:"3px solid black",
        zIndex:9,
        justifyContent:"center",
        marginTop:"-4px",
        boxSizing:"border-box" as "border-box",
        boxShadow: "0px 4px 4px 0px #00000040",
        fontFamily: "CircularStd",
        fontSize: "14px", 
        fontWeight: 450, 
        lineHeight:" 24px", 
        color:"#5A5A5A"
    }
};

const CustomInputStyle = styled(Input)({
    "& .MuiInputBase-input": {backgroundColor: "#fff",
      height: "22px",
      position: "relative",
      zIndex: 1,borderColor: "#000",
      width: '100%',
      paddingLeft: "5px",
      paddingRight: "5px",
      borderStyle: "solid",
      borderWidth: 3, 
      fontFamily:'CircularStd', 
      fontSize:16,
      lineHeight:24,
      fontWeight: 450,
      textAlign:"left",
    },
    "& .MuiInputBase-fullWidth": {
      margin: 0
    },"& .MuiInputAdornment-positionEnd": {position:"absolute",zIndex:1,right:0,
    },"& inpur:before": {content: "none"},
    "& .MuiInputBase-root": {width: '100%'},
    "& .MuiInputBase-input.Mui-disabled":{
      backgroundColor:"#d9d9d9",
      color:"#000"
    }
  });


const AlertBox = styled("div")({
    padding: "8px",
    backgroundColor: "#FEE2E2",
    borderLeftColor: "#FF5B74",
    boxSizing: 'border-box',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    fontFamily: 'CircularStd',
    borderStyle: "solid",
    color: "#FF5B74",
    // width: '74%',
    width: '100%',
    border: 0,
    borderLeft: 4,
    // marginLeft:"67px",
    fontSize:"14px",
    marginLeft: 0,
  });
 
  const MobileNumberWrapper = styled("div")({
    "& .react-tel-input .form-control:focus": {
       boxShadow: "none",  
    }
  })
 
  const UpdateAlertBox = styled("div")({
    padding: "8px",
    backgroundColor: "#FEE2E2",
    borderLeftColor: "#FF5B74",
    boxSizing: 'border-box',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    fontFamily: 'CircularStd',
    borderStyle: "solid",
    color: "#FF5B74",
    width: '100%',
    border: 0,
    borderLeft: 4,
    // marginLeft:"67px",
    fontSize:"14px"
  });

  const ResponsiveDialogContent = styled(DialogContent)({
    '&.pt-100':{
      paddingTop: '100px',
    },
    '@media only screen and (max-width:500px)':{
      width: '390px !important',
      minHeight:'340px !important',
      gap: '20px !important',
      '&.pt-100':{
        paddingTop: '80px',
      },
      '& .closeWrapper':{
        right: '15px !important',
        top: '15px !important'
      },
      '& .closeWrapper >div':{
        height: '18px !important',
        width: '18px !important'
      },
      '& .contentContainer':{
        gap: '18px !important'
      },
      '& .contentImage':{
        padding: '7px !important'
      },
      '& .contentImage >svg':{
        height: '35px !important',
        width: '35px !important'
      },
      '& .calendarImage >div':{
        height: '30px !important',
        width: '30px !important'
      },
      '& .calendarImage':{
        padding: '12px !important'
      },
      '& .heading':{
        fontSize:'24px !important',
        lineHeight: '36px !important'
      },
      '& .text':{
        fontSize:'14px !important',
        lineHeight: '23px !important',
        marginTop: '5px !important',
        maxWidth: '330px !important'
      },
      '& .icalendar-select':{
        maxWidth:'200px !important'
      },
      '& .ContinueButton':{
        width: '150px !important',
        marginBottom: '15px !important'
      },
      '& .warningLogo':{
        width: '60px !important',
        height: '60px !important'
      },
      '& .warningLogo >h2':{
        fontSize: '40px !important'
      },
      '& .proceedButton':{
        marginTop: "20px"
      },
      '& .proceedButton >div>div':{
        width: '100px !important'
      }
    }
  })

 
const Wrapper = styled(`div`)({
    position: 'relative',
    width: '360px',
    height: '44px',
  });
  
  const SearchInput = styled(`input`)({
    width: '100%',
    height: '100%',
    padding: '10px 50px 10px 36px',
    border: '3px solid black',
    outline: 'none',
    boxSizing: 'border-box',
    fontSize:"14px",
    fontFamily:'CircularStd',
    fontWeight: 450,
  });

  const Icon = styled(SearchIcon)({
    position: 'absolute',
    left: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: 'black',
  });
  

  const CustomConfirmationText = styled(`div`)({
    textDecorationStyle: "solid", textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: 16,
    "@media screen and (min-width:420px) and (max-width:500px)": {
      textDecorationStyle: "solid", textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: "15px",
    },
    "@media screen and (max-width:420px)" :{
      textDecorationStyle: "solid", textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: "14px",
    }
  })
// Customizable Area End
