import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Grid,
  Typography,Dialog,DialogContent,DialogContentText,
  IconButton,
  Tooltip
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'
import "react-datepicker/dist/react-datepicker.css";
import {  View , Text} from "react-native";
import { createImage, imgLogo, imgNotification ,imgRightArrow,group_plus,closeBtnIcon} from "./assets";
import AppHeader from "../../../components/src/AppHeader.web";
import { loadingImg } from "../../customform/src/assets";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date:Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

  render() {
    // Customizable Area Start
    const { isLoading } = this.state;
    return (
      <>
        <AppHeader
          logoUrl={imgLogo}
          homeText="Home"
          createImageUrl={createImage}
          helpCenterText="Help Centre"
          onClickHelpCenter={() => this.goToHelpCenterUser()}
          userUrl={createImage}
          settingsIcon
          quoteUrl={group_plus}
          onClickViewEnquiries={() => this.goToEnquiry()}
          onClickViewProfile={() => this.goToProfile()}
          helpCenterNotificationUrl={imgNotification}
          onClickSettings={() => this.goToSettingsUser()}
          onClickQuote={() => this.goToQuote()}
          data-test-id="testAppHeader"
          onPressHome={() => this.goToHome()}
        />
      
        <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth={false} style={{marginTop:25,paddingLeft:80,paddingRight:60,marginBottom:15}}>
            <Text style={{ color:'#5A5A5A', marginBottom: 40,fontFamily: "CircularStd, sans-serif",}} onPress={this.goToHome} data-testid="gotoHome">
              Home
            </Text>  
            <img src={imgRightArrow} width={10} style={{marginLeft:10,marginRight:10}} alt="imagRightArrow" />
            <Text style={{ color:'#594FF5',fontSize:16, marginBottom: 40,fontFamily: "CircularStdBold, sans-serif"}}>
              Profile
            </Text>
          </Container>
          {isLoading ?(<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',width:"100%", backgroundColor: 'transparent' }}>
       <img src={loadingImg}  style={{ paddingTop: "10%", display: 'flex', justifyContent: 'center', alignItems: 'center',height:"40%",width:"12%"}}/>
  </div>):( <Formik
            data-test-id="formik"
            initialValues={{
              clientName: '',
            }}
            onSubmit={(values) => {
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off" style={{ width: "100%" }}>
                <Container maxWidth={false}>
                  <Box sx={webStyle.mainWrapper}>
                    <View style={{ marginLeft: 8 }}>
                      <View style={{ display: "flex", flexDirection: "column" }}>

                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={4} >
                            <Box sx={imageCss}>
                                <img
                                    src={
                                       this.selectViewProfile()
                                      }
                                    alt="profile"
                                    width="100%"
                                    height="100%"
                                    data-test-id="profileImage"
                                />
                              </Box>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              style={{
                                fontSize: "30px",
                                fontWeight: 450,
                                lineHeight: "40px",
                                gap: "32px", letterSpacing: '-0.15px',
                                fontFamily: 'CircularStdBold',
                              }}
                            >{this.state.userProfileData} {this.state.userProfileDatalastname}</Typography>
                            <Typography style={{
                              fontSize: "14px",
                              fontWeight: 450,
                              lineHeight: "24px",
                              gap: "32px",
                              fontFamily: "CircularStd",
                            }}>
                              Account Manager: {this.state.userProfileDataAccountManager}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                gap: "32px",
                                fontFamily: "CircularStd",
                              }}
                            >First Name</Typography>
                            <FormControl fullWidth variant="outlined">
                              <TextField
                                data-test-id="firstName"
                                className="customInput"
                                disabled
                                fullWidth
                                variant="outlined"
                                inputProps={{
                                  style: {
                                    color: 'black',
                                  }
                                }}
                                style={{color: "#000"}}
                                name="First Name"
                                value={this.state.userProfileData}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                gap: "32px",
                                fontFamily: "CircularStd",
                              }}
                            >Last Name</Typography>
                            <FormControl fullWidth variant="outlined">
                              <TextField
                                data-test-id="lastName"
                                className="customInput"
                                disabled
                                fullWidth
                                variant="outlined"
                                value={this.state.userProfileDatalastname}
                                inputProps={{
                                  style: {
                                    color: 'black',
                                  }
                                }}
                                name="Last Name"
                              />
                            </FormControl>

                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                gap: "32px",
                                fontFamily: "CircularStd",
                              }}
                            >Job Title</Typography>
                            <FormControl fullWidth variant="outlined">
                              <Tooltip 
                                data-test-id="jobTitleFieldTooltip"
                                placement="top" 
                                title={this.state.userProfileDataJobTitle}>
                              <TextField
                                data-test-id="jobTitle"
                                className="customInput"
                                disabled
                                fullWidth
                                variant="outlined"
                                value={this.state.userProfileDataJobTitle}
                                inputProps={{
                                  style: webStyle.jobTittleField
                                }}
                                name="Job Title"
                              />
                              </Tooltip>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                gap: "32px",
                                fontFamily: "CircularStd",
                              }}
                            >User Type</Typography>
                            <TextField
                                data-test-id="userType"
                                className="customInput"
                                fullWidth
                                disabled
                                variant="outlined"
                                inputProps={{
                                  style: {
                                    color: 'black',
                                  }
                                }}
                                name="userType"
                                value={this.state.userProfileDatatype}
                              />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                gap: "32px",
                                fontFamily: "CircularStd",
                              }}
                            >Email</Typography>
                            <FormControl fullWidth variant="outlined">
                              <TextField
                                data-test-id="email"
                                className="customInput"
                                fullWidth
                                disabled
                                variant="outlined"
                                inputProps={{
                                  style: {
                                    color: 'black',
                                  }
                                }}
                                name="Email"
                                value={this.state.userProfileDataemail}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                gap: "32px",
                                fontFamily: "CircularStd",
                              }}
                            >Company</Typography>
                            <FormControl fullWidth variant="outlined">
                              <TextField
                                data-test-id="company"
                                className="customInput"
                                disabled
                                fullWidth
                                variant="outlined"
                                value={this.state.userProfileDatacompany}
                                inputProps={{
                                  style: {
                                    color: 'black',
                                  }
                                }}
                                name="Company"
                              />
                            </FormControl>


                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                gap: "32px",
                                fontFamily: "CircularStd",
                              }}
                            >Mobile Number</Typography>
                            <FormControl fullWidth variant="outlined">
                              <PhoneInput
                                data-test-id="mobileNumber"
                                country={'us'}
                                disabled
                                value={this.state.userProfileDatamobile}
                                inputStyle={webStyle.phoneNumberField}
                                buttonStyle={{borderLeft:"3px solid #000", borderTop:"3px solid #000",borderBottom:"3px solid #000", backgroundColor:"#fff",borderRadius:0,borderRight:"0px"}}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                gap: "32px",
                                fontFamily: "CircularStd",
                              }}
                            >Location</Typography>
                            <FormControl fullWidth variant="outlined">
                            <Tooltip 
                                data-test-id="locationTitleFieldTooltip"
                                placement="top" 
                                title={this.state.userProfileDatalocation}>
                              <TextField
                                data-test-id="location"
                                className="customInput"
                                fullWidth
                                disabled
                                variant="outlined"
                                inputProps={{
                                  style: webStyle.jobTittleField
                                }}
                                value={this.state.userProfileDatalocation}
                                name="Location"
                              />
                               </Tooltip>
                            </FormControl>

                          </Grid>

                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div data-testid="allPackage" className={`buttonWrapper w-195 btn-blue`} style={{ marginRight: 25, marginTop: '40px' }}>
                              <div className="buttonOutline"></div>
                              <Button data-test-id="editProfile" 
                                className="btn-sm"
                                onClick={this.goToEdit}
                              >Edit Profile</Button>
                            </div>
                          </Grid>

                        </Grid>
                      </View>
                    </View>


                  </Box>
                  {/* Customizable Area Start */}
               

                  {/* Customizable End Start */}
                </Container>
              </form>
            )}
          </Formik>)}
          <Dialog
            open={this.state.isSuspended}
            onClose={this.handleGoToLoginPage}
            aria-labelledby="suspended-account-dialoge"
            aria-describedby="suspended-account-dialoge-description"
            data-test-id="suspendedModal">
            <Box style={accountSuspendedModal.container} >
              <IconButton
                disableTouchRipple
                disableRipple
                disableFocusRipple
                data-test-id="suspendedModalCloseBtn"
                onClick={this.handleGoToLoginPage}
                style={accountSuspendedModal.crossMarkCloseBtn}>
                <img src={closeBtnIcon}/>
              </IconButton>
              <Box style={accountSuspendedModal.modalContentWrapper}>
                <Box style={accountSuspendedModal.crossMark}>
                  <ClearIcon style={accountSuspendedModal.crossMarkIcon} />
                </Box>
                <Typography style={accountSuspendedModal.suspendedTitle}>
                  {this.state.suspendedTitle}
                </Typography>
                <DialogContent>
                  <DialogContentText
                    style={accountSuspendedModal.suspendedMessage}
                    id="suspended-account-dialoge-description"
                    data-test-id="suspendedMessageID">
                    {this.state.suspendedMessage}
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Box>
          </Dialog>
        </ThemeProvider >
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const imageCss = {
  border: "3px solid #000",
  objectFit: "cover",
  boxSizing: "border-box",
  width: "150px",
  height: "150px"
};

const accountSuspendedModal = {
  container:{
    maxWidth: '100%', 
    width: '480px', 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: "center",
    border: '3px solid black', 
    boxSizing: 'border-box' as "border-box", 
    minHeight: '465px',
  },
  modalContentWrapper:{
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  crossMark:{
    backgroundColor: '#FF5B74',
    border: 'solid 3px #000',
    width: 'fit-content', 
    padding: '12px',
    marginBottom:12
  },
  crossMarkIcon:{
    height: '55px',
    color: 'black',
    width: '55px',
  },
  crossMarkCloseBtn:{
    position: "absolute" as "absolute", 
    top: '3px', 
    right: '3px'
  },
  crossMarkCloseIcon:{ 
    fontWeight: 900, 
    color: "black", 
    fontSize: "25x", 
    width: "25px", 
    height: "25px"
  },
  suspendedTitle:{
    fontSize:"clamp(1.5rem, 1.3917rem + 0.5333vw, 1.875rem)",
    color:"black",
    padding:10,
    fontWeight:700,
    fontFamily: 'CircularStd',
    marginBottom:14
  },
  suspendedMessage:{
    textAlign:"center" as "center",
    fontSize:"14px",
    fontFamily: 'CircularStd',
    color:"#5A5A5A",
    paddingInline:"20px"
  },
}

const webStyle = {
  mainWrapper: {
    padding: "30px",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    border: '3px solid #000',
    maxWidth: '804px',
    marginLeft:'50px',
    height: '659px',
    marginTop: '20px',
    marginBottom:'80px'
  },
  titletext: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    gap: "32px"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  profileTitle: {
    color: '#000',
    fontSize: "30px",
    fontWeight: '700',
    marginBottom: 5,
    fontFamily: 'CircularStdBold',
    display: "flex",
  },
  subTiitle: {
    fontSize: 14,
    color: '#000',
    fontFamily: 'CircularStd',
  },
  custominputclass: {
    border: '3px solid #000',
    color: "black"
  },
  jobTittleField: {
    color: 'black',
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight:"3px"
  },
  phoneNumberField:{ border: "3px solid #000",width:"100%",height:'43px',color: 'black',borderRadius:0 }
};
// Customizable Area End
