Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "helpcentre";
exports.labelBodyText = "helpcentre Body";

exports.helpcentreAPIEndPoint = "/help_centre/question_type";
exports.httpGetType = "GET";
exports.getViewUserProfileEndPoint = "account_block/accounts/specific_account";
exports.btnExampleTitle = "CLICK ME";exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";exports.emailTitle = "Email";
exports.numberTitle = "Phone number";exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";exports.placeHolderEmail = "Email";
exports.contactUs = "Contact us";exports.lableFirstName ='First Name*'
exports.lableLastName ='Last Name*';exports.lableEmail ='Email Id*'
exports.lablePhoneNumber ='Phone Number';exports.lableCompanyName ='Company Name'
exports.lableHelpDescription ='Tell us how we can help in few words'
exports.lableAttachBrief ='Attach brief';exports.lableAttachBriefButtonContent ='Choose file'
exports.lableAttachBriefCustomContent ='No file chosen';exports.lableSubmitButton='Submit'
exports.fnameRequired='First name is required';
exports.lnameRequired='Last name is required'
exports.emailRequired='Please enter a valid Email ID';exports.emailBlockEndpint='bx_block_contact_us/contacts'
exports.getFAQEndPoint = "bx_block_help_centre/question_answer";
exports.getFAQSearchEndPoint = "bx_block_help_centre/question_answer/search_question?question="
exports.supsendedModalTitle = "Account Suspended";
exports.deactivatedTitle = "Account Deactivated"
exports.supspendedModalOkBtn = "OK";
exports.suspendedUserEndPoint = "/account_block/accounts/check_company_suspension";
exports.errorSubjectFieldValue = "Please provide a value for the 'Subject' field";
exports.errorTellUsValue = "Please provide a value for the 'Tell us how we can help in a few words' field"
// Customizable Area End
